// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Customer_setting__c4EY2 {
  display: flex;
  justify-content: space-between;
}

.Customer_add__Isg0c {
  display: flex;
}

.Customer_add__Isg0c > div {
  padding: 0px 5px;
}

.Customer_check__WgMxB {
  display: flex;
  align-items: center;
}

.Customer_check__WgMxB > div {
  margin-right: 10px;
}

.Customer_info__w-Dow {
  display: flex;
  margin: 20px 0px;
  background-color: rgba(255, 255, 255, 0.9450980392);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.Customer_info__w-Dow > div {
  width: 120px;
  margin-right: 10px;
}

.Customer_dashboard__LqFMg {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.Customer_search__GhTCV {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.Customer_search__GhTCV > div {
  margin-right: 10px;
}

.Customer_filterAndSearch__wk4SR {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Customer_filter__7gPj6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.Customer_filter__7gPj6 > div {
  margin-left: 5px;
}

.Customer_nameInfo__UjjTE {
  display: block;
  width: 100%;
  height: 100vh;
  overflow: "scroll";
}`, "",{"version":3,"sources":["webpack://./src/pages/Customer/Customer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;EACA,mDAAA;EACA,aAAA;EACA,kBAAA;EACA,yHAAA;AACJ;;AAGA;EACI,YAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAAJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AACJ","sourcesContent":[".setting {\n    display: flex;\n    justify-content: space-between;\n}\n\n.add {\n    display: flex;\n}\n\n.add > div {\n    padding: 0px 5px;\n}\n\n.check {\n    display: flex;\n    align-items: center;\n}\n\n.check > div {\n    margin-right: 10px;\n}\n\n.info {\n    display: flex;\n    margin: 20px 0px;\n    background-color: #fffffff1;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),\n        0px 1px 5px 0px rgba(0, 0, 0, 0.12);\n}\n\n.info > div {\n    width: 120px;\n    margin-right: 10px;\n}\n\n.dashboard {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    margin-top: 10px;\n}\n\n.search {\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n}\n\n.search > div {\n    margin-right: 10px;\n}\n\n.filterAndSearch {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.filter {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 8px;\n}\n.filter > div {\n    margin-left: 5px;\n}\n\n.nameInfo {\n    display: block;\n    width: 100%;\n    height: 100vh;\n    overflow: 'scroll';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": `Customer_setting__c4EY2`,
	"add": `Customer_add__Isg0c`,
	"check": `Customer_check__WgMxB`,
	"info": `Customer_info__w-Dow`,
	"dashboard": `Customer_dashboard__LqFMg`,
	"search": `Customer_search__GhTCV`,
	"filterAndSearch": `Customer_filterAndSearch__wk4SR`,
	"filter": `Customer_filter__7gPj6`,
	"nameInfo": `Customer_nameInfo__UjjTE`
};
export default ___CSS_LOADER_EXPORT___;
