import * as yup from 'yup';

export const exportForm = [
    {
        name: '',
        contact: '',
        source: '',
        package: '',
        pay: '',
        potential: '',
        date_ex: '',
        date_pay: '',
        action: '',
    },
    // ... more data
];

export const monthSelect = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const yearSelect = ['2023', '2024'];

export const titleCustomerExcel = [
    'name',
    'contact',
    'note',
    'source',
    'type',
    'potential',
    'package',
    'date_ex',
    'date_pay',
    'pay',
    'action',
];

export const yupCustomer = yup.object().shape({
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
    date_pay: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const yupCustomerPay = yup.object().shape({
    pay: yup.string().required('Vui lòng nhập dữ liệu!'),
    date_pay: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const infoCustomerPay = [
    { name: 'date_pay', label: 'Ngày thanh toán:', type: 'date' },
    { name: 'pay', label: 'Thanh toán', type: 'number' },
    { name: 'note', label: 'Ghi chú', type: 'note' },
];

function countMonthsBetweenDates(dateBegin, dateEnd) {
    // Chuyển đổi các chuỗi ngày thành đối tượng Date
    const begin = new Date(dateBegin);
    const end = new Date(dateEnd);

    // Tính toán số tháng
    const monthsBetween = (end.getFullYear() - begin.getFullYear()) * 12 + (end.getMonth() - begin.getMonth());

    return monthsBetween + 1;
}

export const checkKPI = (value, kpi) => {
    if (localStorage.getItem('dateBegin') && localStorage.getItem('dateEnd')) {
        kpi = countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')) * kpi;
    }
    return Math.floor((value * 100) / kpi);
};

export const checkKPIColor = (value, kpi) => {
    console.log(countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')));
    if (localStorage.getItem('dateBegin') && localStorage.getItem('dateEnd')) {
        kpi = countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')) * kpi;
    }
    const colorCheck = Math.floor((value * 100) / kpi);
    if (colorCheck < 50) {
        return 'red';
    }
    if (colorCheck < 80) {
        return '#f0ad4e';
    }
    return '#337ab7';
};

export const chartSelect = [
    'Doanh thu hàng tháng',
    'Tỷ lệ doanh thu khách lẻ và khách đoàn',
    'Lượng khách theo tiềm năng',
    'Doanh thu theo các dòng sản phẩm',
    'Doanh thu nhân viên hàng tháng',
];

export const charTypeSelect = ['Tất cả', 'Khách đoàn', 'Khách lẻ'];
