import axiosClinet from '~/api/axiosClient';

const packApi = {
    getPack(data) {
        const url = '/pack/getpack';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
};

export default packApi;
