import { TextField, Button, Dialog } from '@mui/material';
import style from './Report.module.scss';
import { convertMoney, convertDate } from '~/tool/convert';
import TextDate from '~/compoents/TextDate/TextDate';
import { useState, useEffect } from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import customerApi from '~/api/customer/customer';
import { convertNull } from '~/tool/convert';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CheckCustomer from '~/compoents/CheckCustomer/CheckCustomer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TodoForm from '~/compoents/TodoForm';
import { yupCustomer } from '~/pages/Customer/data/static';
import { infoCustomerOld, yupCustomerOld, infoCustomerNew, yupCustomerNew } from './data/static.js';
import AddCustomerReport from '../AddCustomerReport';
import DeleteIcon from '@mui/icons-material/Delete';

function Report({ show, setShow, dataReport, load, reportID }) {
    const [beginDs, setBeginDs] = useState(dataReport.beginDs);
    const [beginDk, setBeginDk] = useState(dataReport.beginDk);

    const [endDs, setEndDs] = useState(dataReport.endDs);
    const [endDk, setEndDk] = useState(dataReport.endDk);

    const [gt, setGt] = useState(dataReport.gt);
    const [gp, setGp] = useState(dataReport.gp);

    const [dataDs, setDataDs] = useState([]);
    const [dataDk, setDataDk] = useState([]);

    const [loading, setLoading] = useState(false);

    const [checkView, setcheckView] = useState(false);
    const [dataCustomerCheck, setdataCustomerCheck] = useState([]);

    const [noteAdmin, setNoteAdmin] = useState(dataReport.noteAdmin);

    //////
    const [showForm, setShowForm] = useState(false);
    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });
    const [formInfo, setformInfo] = useState({});

    const [defaultForm, setDefaultForm] = useState([]);

    const [showOld, setShowOld] = useState(false);

    const [customerKHDK, setCustomerKHDK] = useState([]);

    useEffect(() => {
        console.log(reportID);
        if (reportID) {
            handleCheckDs();
            handleCheckDk();
        }
    }, [reportID]);

    const handleCheckDs = async () => {
        setLoading(true);
        const data = await customerApi.getReportDs({
            beginDs,
            endDs,
        });
        if (data) {
            console.log(data);
            setDataDs(data);
            setLoading(false);
        }
    };
    const handleCheckDk = async () => {
        setLoading(true);
        const data = await customerApi.getReportDk({
            beginDk,
            endDk,
            reportID,
        });
        console.log(data);
        if (data) {
            setDataDk(data);
            setLoading(false);
            setCustomerKHDK(data.dataCustomerKHDK);
        }
    };

    const saveReport = async () => {
        if (!reportID) {
            const data = await customerApi.saveReport({
                beginDs,
                endDs,
                gt,
                beginDk,
                endDk,
                gp,
                customerKHDK,
            });
            load();
            if (data.result) {
                setShow(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Thêm báo cáo thành công!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            const data = await customerApi.editReport({
                beginDs,
                endDs,
                gt,
                beginDk,
                endDk,
                gp,
                reportID,
            });
            load();
            console.log(data);
            if (data.result) {
                Swal.fire({
                    icon: 'success',
                    title: data.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
                setShow(false);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    const checkCustomerds = async (dt, type, potential) => {
        // alert(dt + '-' + type + '-' + potential);
        const dataTableNew = await customerApi.getCheckCustomer({
            dt,
            type,
            potential,
            beginDs,
            beginDk,
            endDs,
            endDk,
            sale: localStorage.getItem('user'),
        });

        if (dataTableNew.length) {
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            setdataCustomerCheck(dataStt);
            setcheckView(true);
        } else {
            setdataCustomerCheck([]);
            setcheckView(true);
        }
        console.log(dataTableNew);
    };

    const addReportCustomerOld = async () => {};
    const addReportCustomerNew = async () => {};
    // Test
    // const openReportCustomerOld = async () => {
    //     setConfirmForm({
    //         title: 'Thêm báo cáo khách hiện hữu :',
    //         confirm: addReportCustomerOld,
    //     });
    //     setDefaultForm({});
    //     setformInfo({
    //         info: infoCustomerOld,
    //         condition: yupCustomerOld,
    //     });
    //     setShowForm(true);
    // };

    const openReportCustomerOld = async () => {
        // setShow(false);
        setShowOld(true);
    };

    const openReportCustomerNew = async () => {
        setConfirmForm({
            title: 'Thêm báo cáo khách mới :',
            confirm: addReportCustomerNew,
        });
        setDefaultForm({});
        setformInfo({
            info: infoCustomerNew,
            condition: yupCustomerNew,
        });
        setShowForm(true);
    };

    const addKHDK = async (data) => {
        if (reportID) {
            const add = await customerApi.addCustomerKHDK({ ...data, reportID });
            if (add.result) {
                Swal.fire({
                    icon: 'success',
                    title: add.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
                handleCheckDk();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: add.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            var khdk;
            customerKHDK.length ? (khdk = [...customerKHDK, data]) : (khdk = [data]);
            setCustomerKHDK(khdk);
        }
    };

    const removeCustomerKHDK = async (id, stt) => {
        if (id) {
            const data = await customerApi.removeCustomerKHDK({ id });
            if (data.result) {
                Swal.fire({
                    icon: 'success',
                    title: data.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
                handleCheckDk();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.noti,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            // Tạo một bản sao của mảng customerKHDK
            const updatedKHDK = [...customerKHDK];
            // Xóa phần tử khdk tại vị trí stt
            updatedKHDK.splice(stt, 1);
            // Cập nhật state với mảng đã được cập nhật
            setCustomerKHDK(updatedKHDK);
        }
    };

    return (
        <Dialog
            maxWidth={false}
            open={show}
            onClose={handleClose}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <div>
                {showOld && (
                    <AddCustomerReport
                        show={showOld}
                        setShow={setShowOld}
                        addKHDK={addKHDK}
                        name="Thêm kế hoạch dự kiến:"
                    />
                )}
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            {checkView && <CheckCustomer customerTable={dataCustomerCheck} show={checkView} setShow={setcheckView} />}
            {loading && (
                <Box sx={{ width: '100%', position: 'sticky', top: 0 }}>
                    <LinearProgress />
                </Box>
            )}

            <div className={style.reportAll}>
                <h3>Nhân viên: {localStorage.getItem('user')}</h3>

                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="6" style={{ textAlign: 'center' }}>
                                KH từ đầu tháng
                            </td>
                        </tr>
                        <tr rowspan="2" className={style.backColor}>
                            <td className={style.tdBegin}>Doanh thu từ đầu tháng</td>
                            <td className={style.tdBegin}>Công nợ từ đầu tháng</td>
                            <td>%</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDs.dst))} ₫
                            </td>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDs.debt))} ₫
                            </td>
                            <td rowspan="2" className={style.money}>
                                {dataDs.dst ? Math.round((dataDs.dst * 100) / 1000000000) : 0}
                            </td>

                            <td>Lẻ</td>
                            {dataDs.potentialMonth && dataDs.potentialMonth.length > 0
                                ? dataDs.potentialMonth.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dst', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDs.potentialMonth && dataDs.potentialMonth.length > 0
                                ? dataDs.potentialMonth.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dst', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                    </table>
                </div>
                <h2 style={{ margin: '10px', textAlign: 'center' }}>Báo cáo tuần</h2>

                <h3>I. Kết quả tuần:</h3>
                <div className={style.timeSet}>
                    <TextDate label="Từ ngày" value={beginDs} setValue={setBeginDs}></TextDate> &nbsp; &nbsp;
                    <TextDate label="Đến ngày" value={endDs} setValue={setEndDs}></TextDate> &nbsp; &nbsp;
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            sx={{ padding: '7px 20px' }}
                            type="submit"
                            size="small"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={handleCheckDs}
                        >
                            Check
                        </Button>
                    </div>
                </div>
                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"> </td>
                            <td colspan="6" style={{ textAlign: 'center' }}>
                                KH đạt được
                            </td>
                        </tr>
                        <tr className={style.backColor}>
                            <td className={style.tdBegin}>Doanh thu thực tế</td>
                            <td className={style.tdBegin}>SL Khách đã liên hệ</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td
                                rowspan="2"
                                className={style.money}
                                onClick={() => checkCustomerds('dsct', 'All', 'No')}
                            >
                                {convertMoney(convertNull(dataDs.ds))} ₫
                            </td>
                            <td
                                rowspan="2"
                                className={style.money}
                                onClick={() => checkCustomerds('meet', 'All', 'No')}
                            >
                                {dataDs.infoMeet}
                            </td>
                            <td>Lẻ</td>
                            {dataDs.potential && dataDs.potential.length > 0
                                ? dataDs.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('ds', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDs.potential && dataDs.potential.length > 0
                                ? dataDs.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('ds', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td colspan="6">
                                <TextField
                                    fullWidth
                                    label="Thông tin"
                                    multiline
                                    maxRows={5}
                                    variant="standard"
                                    value={gt}
                                    onChange={(event) => setGt(event.target.value)}
                                    inputProps={{ style: { fontSize: 12 } }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                <h3 style={{ marginTop: '20px' }}> II. Kế hoạch tuần tiếp theo</h3>
                <div className={style.timeSet}>
                    <TextDate label="Từ ngày" value={beginDk} setValue={setBeginDk}></TextDate> &nbsp; &nbsp;
                    <TextDate label="Đến ngày" value={endDk} setValue={setEndDk}></TextDate> &nbsp; &nbsp;
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            sx={{ padding: '7px 20px' }}
                            type="submit"
                            size="small"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={handleCheckDk}
                        >
                            Check
                        </Button>
                    </div>
                </div>
                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="5" style={{ textAlign: 'center' }}>
                                KH Dự kiến
                            </td>
                        </tr>
                        <tr className={style.backColor}>
                            <td className={style.tdBegin}>Doanh số dự kiến</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDk.ds))} ₫
                            </td>
                            <td>Lẻ</td>
                            {dataDk.potential && dataDk.potential.length > 0
                                ? dataDk.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dk', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDk.potential && dataDk.potential.length > 0
                                ? dataDk.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dk', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td colspan="6">
                                <Button
                                    sx={{ padding: '7px 20px' }}
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    component="label"
                                    startIcon={<AddCircleIcon />}
                                    onClick={openReportCustomerOld}
                                >
                                    Kế hoạch khách hiện hữu
                                </Button>

                                <Button
                                    style={{ display: 'none' }}
                                    sx={{ padding: '7px 20px', marginLeft: '20px' }}
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    component="label"
                                    startIcon={<AddCircleIcon />}
                                    onClick={openReportCustomerNew}
                                >
                                    Kế hoạch khách mới
                                </Button>
                                <div style={{ marginTop: '10px' }}>
                                    {customerKHDK.length > 0 && (
                                        <table>
                                            <tr>
                                                <th>Khách hàng</th>
                                                <th>Kế hoạch</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            {customerKHDK.map((khdk, index) => (
                                                <tr>
                                                    <td>{khdk.customerName}</td>
                                                    <td>{khdk.status}</td>
                                                    <td>{khdk.note}</td>
                                                    <td>
                                                        <DeleteIcon
                                                            idKHDK={khdk.id}
                                                            stt={index}
                                                            onClick={() => {
                                                                removeCustomerKHDK(khdk.id, index);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <TextField
                                    fullWidth
                                    label="Đề xuất giải pháp, kế hoạch tuần:"
                                    value={gp}
                                    onChange={(event) => setGp(event.target.value)}
                                    multiline
                                    maxRows={5}
                                    variant="standard"
                                    inputProps={{ style: { fontSize: 12 } }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                        </tr>
                    </table>
                    <div className={style.evaluate}>
                        <h3 style={{ backgroundColor: '#f1f1f1', padding: '10px' }}>Đánh giá:</h3>

                        <div>
                            <TextField
                                fullWidth
                                label="Yêu cầu chỉnh sửa báo cáo"
                                value={noteAdmin}
                                onChange={(event) => setNoteAdmin(event.target.value)}
                                multiline
                                rows={3}
                                maxRows={15}
                                variant="standard"
                                inputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={style.xacnhan}>
                    <Button
                        sx={{ padding: '7px 20px' }}
                        type="submit"
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<AssignmentIcon />}
                        onClick={saveReport}
                    >
                        Xác nhận báo cáo tuần
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default Report;
