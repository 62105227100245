import { useState, useEffect } from 'react';
import style from './Procedure.module.scss';
import { Button, TextField } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import TodoForm from '~/compoents/TodoForm';
import { yupProcedure, sampleProcedure } from './data/static.js';
import { procedureApi } from '~/api/procedure/procedure';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, NavLink } from 'react-router-dom';

import TodoTable from '~/compoents/TodoTable/TodoTable.js';

import Swal from 'sweetalert2';

function Procedure() {
    const [dataProcedure, setDataProcedure] = useState([]);
    const [defaultForm, setDefaultForm] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formInfo, setformInfo] = useState({});
    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });

    useEffect(() => {
        getProcedure();
    }, []);

    const getProcedure = async () => {
        const dataTableNew = await procedureApi.getProcedure();
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setDataProcedure(dataStt);
    };
    const addProcedure = async (data) => {
        const check = await procedureApi.addProcedure(data);
        console.log(check);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getProcedure();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };
    const addProcedureForm = () => {
        setConfirmForm({
            title: `Thêm thông tin quy trình`,
            confirm: addProcedure,
        });
        setDefaultForm({});
        setformInfo({
            info: sampleProcedure,
            condition: yupProcedure,
        });
        setShowForm(true);
    };

    const editProcedure = async (data) => {
        const check = await procedureApi.editProcedure(data);
        if (check.result) {
            setShowForm(false);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getProcedure();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editProcedureForm = (data) => {
        setConfirmForm({
            title: 'Chỉnh sửa thông tin quy trình',
            confirm: editProcedure,
        });
        setDefaultForm(data);
        setformInfo({
            info: sampleProcedure,
            condition: yupProcedure,
        });
        setShowForm(true);
    };
    const removeProcedure = async (data) => {
        console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Xoá quy trình?',
            text: data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await procedureApi.removeProcedure(data);
            if (resultRemove.result) {
                Swal.fire('Đã xoá.', 'success');
                getProcedure();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };
    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 0.3 },
        {
            field: 'name',
            headerName: 'Quy trình',
            minWidth: 150,
            flex: 5,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.name}
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 150,
            flex: 3,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.description}
                </div>
            ),
        },
        {
            field: 'link',
            headerName: 'Đường dẫn',
            minWidth: 150,
            flex: 2,
            renderCell: (params) => (
                <NavLink to={params.row.link} target="_blank">
                    {params.row.link}
                </NavLink>
            ),
        },
        {
            field: 'Actions',
            headerName: 'Tuỳ chỉnh',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <EditIcon
                        onClick={() => editProcedureForm(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removeProcedure(params.row)} style={{ cursor: 'pointer' }} />
                </div>
            ),
        },
    ];
    return (
        <div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            <div>
                <div>
                    <Button
                        sx={{}}
                        variant="contained"
                        onClick={addProcedureForm}
                        component="label"
                        startIcon={<AddBox />}
                    >
                        Thêm quy trình làm việc
                    </Button>
                </div>
            </div>
            <div>
                <TodoTable rows={dataProcedure} columns={columns} />
            </div>
        </div>
    );
}

export default Procedure;
