// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --blue-pro: rgb(39, 170, 225);
  --green-pro: #61ce70;
  --orange-pro: #f55134;
  --blue-dark-pro: #355895;
  --font-pro: "Bai Jamjuree", sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-pro);
  font-size: 1.3rem;
  text-rendering: optimizeSpeed;
  background-color: #f0f2f5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin-bottom: 10px;
  color: #25356d;
}

.swal2-container {
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/main.scss"],"names":[],"mappings":"AAGA;EACI,6BAAA;EACA,oBAAA;EACA,qBAAA;EACA,wBAAA;EACA,sCAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,4BAAA;EACA,iBAAA;EACA,6BAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,SAAA;EACA,UAAA;AAAJ;;AAGA;;;EAGI,mBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ","sourcesContent":["@import './normalize.css';\n@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@500&display=swap');\n\n:root {\n    --blue-pro: rgb(39, 170, 225);\n    --green-pro: #61ce70;\n    --orange-pro: #f55134;\n    --blue-dark-pro: #355895;\n    --font-pro: 'Bai Jamjuree', sans-serif;\n}\n\nhtml {\n    font-size: 62.5%;\n}\n\nbody {\n    font-family: var(--font-pro);\n    font-size: 1.3rem;\n    text-rendering: optimizeSpeed;\n    background-color: #f0f2f5;\n}\n\n* {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n}\n\nh1,\nh2,\nh3 {\n    margin-bottom: 10px;\n    color: #25356d;\n}\n\n.swal2-container {\n    z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
