import { useState, useEffect } from 'react';
import style from './Paper.module.scss';
import { NavLink } from 'react-router-dom';
import { paperApi } from '~/api/paper/paper';

import TodoTable from '~/compoents/TodoTable/TodoTable.js';

import Swal from 'sweetalert2';

function PaperInfo() {
    const [dataPaper, setDataPaper] = useState([]);
    useEffect(() => {
        getPaper();
    }, []);

    const getPaper = async () => {
        const dataTableNew = await paperApi.getPaper();
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setDataPaper(dataStt);
    };

    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 0.3 },
        {
            field: 'name',
            headerName: 'Quy trình',
            minWidth: 150,
            flex: 5,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.name}
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 150,
            flex: 3,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.description}
                </div>
            ),
        },
        {
            field: 'link',
            headerName: 'Đường dẫn',
            minWidth: 150,
            flex: 2,
            renderCell: (params) => (
                <NavLink to={params.row.link} target="_blank">
                    {params.row.link}
                </NavLink>
            ),
        },
    ];
    return (
        <div>
            <div>
                <TodoTable rows={dataPaper} columns={columns} />
            </div>
        </div>
    );
}

export default PaperInfo;
