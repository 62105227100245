import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { publickRoutes, privateRoutes } from '~/routes';
import NullLayout from '~/layouts/NullLayout';
import NotFoundPage from '~/layouts/NullLayout';
import ThemeMui from './assets/ThemeMui';

function App() {
    return (
        <ThemeMui>
            <Router>
                {/* <GlobalContext.Provider value={[globalState, setGlobalState]} > */}
                <div className="App">
                    <Routes>
                        {publickRoutes.map((value, index) => {
                            const Layout = value.layout || NullLayout;
                            const Page = value.component;
                            return (
                                <Route
                                    key={index}
                                    path={value.path}
                                    element={
                                        <Layout path={value.path}>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}

                        {privateRoutes.map((value, index) => {
                            const Layout = value.layout || NullLayout;
                            const Page = value.component;
                            return (
                                <Route
                                    key={index}
                                    path={value.path}
                                    element={
                                        <Layout path={value.path}>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}

                        <Route element={<NotFoundPage />} />
                    </Routes>
                </div>
            </Router>
        </ThemeMui>
    );
}

export default App;
