import * as React from 'react';
import styleNew from './CheckPotential.module.scss';

const severity = {
    Done: 'rgb(18, 132, 255)',
    High: 'green',
    Medium: '#875b26',
    Low: '#c6c6c6',
};

function CheckPotential({ potential }) {
    return (
        <div>
            <div className={styleNew.info} style={{ backgroundColor: severity[potential] }}>
                <div>{potential}</div>
            </div>
        </div>
    );
}

export default CheckPotential;
