import { useState, useEffect } from 'react';
import style from './Month.module.scss';
import { NavLink } from 'react-router-dom';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import Swal from 'sweetalert2';
import adminApi from '~/api/admin/admin.js';
import customerApi from '~/api/customer/customer';
import { convertMoney2, convertDate, convertMoney } from '~/tool/convert';
import { getMonthToday } from '~/tool/timeToday';
import CheckPotential from '~/pages/compoents/CheckPotential/CheckPotential';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ViewCustomrInfo from '../compoents/ViewCustomerInfo';
import TodoForm from '~/compoents/TodoForm';
import { infoGroupMonth, yupInfoGroup, monthsAR } from './data/static';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Button, TextField } from '@mui/material';
import Customer from '../Customer/index';
import { number } from 'yup';

const checkMonthvalue = (month, value) => {
    month = Number(month);
    if (month > getMonthToday() - 1) {
        return 0;
    }
    return value;
};

function Month() {
    const [year, setYear] = useState(2024);
    // const [month, setMonth] = useState(getMonthToday() + 1);
    const [month, setMonth] = useState(getMonthToday());
    const [sumMonth, setSumMonth] = useState([]);
    const [report, setReport] = useState([]);
    const [dataCustomerID, setDataCustomerID] = useState(false);
    const [showCustomerInfoID, setShowCustomerInfoID] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formInfo, setformInfo] = useState({});
    const [defaultForm, setDefaultForm] = useState([]);
    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });
    const [allocationAll, setAllocationAll] = useState(0);

    const [customerTable, setCustomerTable] = useState([]);

    useEffect(() => {
        getReport();
    }, []);

    const getReport = async () => {
        // getSumMonth();
        const dataTableNew = await customerApi.getReportMonth();
        setReport(dataTableNew);
        console.log('Check: dataCustomerTable');
        console.log(dataTableNew);

        // dataTableNew.map((report, index) => {});

        const all = dataTableNew.reduce((accumulator, currentValue) => {
            let kq = checkMonthvalue(
                currentValue.report_month,
                Number(currentValue.total_amount / 1000000 - currentValue.target),
            );
            return accumulator + kq;
        }, 0);
        console.log(-all.toFixed(0));
        setAllocationAll(-all.toFixed(0));

        // const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        // setReport(dataStt);
        const dataCustomerTable = await customerApi.getReportCustomerMonth({ month });

        const dataStt = dataCustomerTable.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setCustomerTable(dataStt);
    };
    const getSumMonth = async () => {
        const dataTableNew = await customerApi.getSumMonth({ year });
        console.log('dataTableNew: ________');
        console.log(dataTableNew);
        setSumMonth(dataTableNew);

        // const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        // setReport(dataStt);
    };

    const checkYear = (e) => {
        e.preventDefault();
        getReport();
    };

    const addMonth = async (data) => {
        const check = await customerApi.addMonth(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getReport();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editMonth = async (data) => {
        console.log('Edit month');
        console.log(data);
        const check = await customerApi.editMonth(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getReport();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editMonthForm = async (event) => {
        const customerID = event.target.getAttribute('customerID');
        const month = Number(event.target.getAttribute('month'));
        const value = event.target.getAttribute('quantity');
        const dateBegin = event.target.getAttribute('dateBegin');
        const dateEnd = event.target.getAttribute('dateEnd');
        const id = event.target.getAttribute('id');

        // customerID, month, value
        setformInfo({
            info: infoGroupMonth,
            condition: yupInfoGroup,
        });
        console.log(value);

        if (value == undefined || value == '') {
            console.log('Add New:');
            setConfirmForm({
                title: 'Tháng: ' + month,
                confirm: addMonth,
            });
        } else {
            setConfirmForm({
                title: 'Tháng:: ' + month,
                confirm: editMonth,
            });
        }
        setDefaultForm({
            quantity: value,
            id: customerID,
            customerID: customerID,
            month,
            year,
            dateBegin,
            dateEnd,
            id,
        });
        setShowForm(true);
    };

    const clickMonth = async (event) => {
        alert(event.target.getAttribute('quantity'));
    };

    const viewCustomrID = async (data) => {
        const dataID = await customerApi.getCustomerIDInfo({ customerID: data.customerID });
        setDataCustomerID(dataID);
        setShowCustomerInfoID(true);
    };
    const columns = [
        {
            field: 'customerID',
            headerName: 'Xem',
            maxWidth: 30,
            renderCell: (params) => (
                <WysiwygIcon
                    onClick={() => viewCustomrID(params.row)}
                    style={{ cursor: 'pointer', marginRight: '8px' }}
                />
            ),
        },
        {
            field: 'stt',
            headerName: '.STT',
            maxWidth: 30,
        },
        {
            field: 'name',
            headerName: '.Khách hàng',
            minWidth: 250,
        },
        {
            field: 'potential',
            headerName: 'Tiềm năng',
            minWidth: 60,
            renderCell: (params) => <CheckPotential potential={params.row.potential} />,
            flex: 3,
        },
        { field: 'status', headerName: 'Trạng thái', minWidth: 150, flex: 5 },
        {
            field: 'status_pay',
            headerName: 'Số tiền (₫)',
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#28529a' }}>
                        {convertMoney(params.row.pay)} ₫
                    </div>
                    <div style={{ fontSize: '10px' }}>{params.row.status_pay}</div>
                </div>
            ),
            flex: 5,
        },
        {
            field: 'date_pay',
            headerName: '.Ngày thanh toán',
            minWidth: 150,
            renderCell: (params) => <div style={{ color: 'rgb(40, 82, 154)' }}>{params.row.date_pay}</div>,
            flex: 5,
        },
        // {
        //     field: 'input',
        //     headerName: '.Ngày cập nhật',
        //     minWidth: 150,
        // },
        // {
        //     field: 'date_ex',
        //     headerName: 'Ngày khám',
        //     minWidth: 150,
        // },
        // {
        //     field: 'type',
        //     headerName: '.Loại',
        //     minWidth: 150,
        // },
        // { field: 'package', headerName: 'Gói', minWidth: 150 },
        // // { field: 'expected', headerName: 'Dự kiến (Đã bỏ)', minWidth: 150 },

        // { field: 'contact', headerName: 'Liên hệ', minWidth: 150 },
        // { field: 'source', headerName: 'Nguồn', minWidth: 150 },
        // { field: 'note', headerName: 'Ghi chú', minWidth: 150 },
    ];

    return (
        <div>
            <div>
                <form onSubmit={checkYear} className={style.check}>
                    <div style={{ marginRight: '10px' }}>
                        <TextField
                            sx={{ width: '100px' }}
                            margin="normal"
                            size="small"
                            label="Tháng"
                            type="text"
                            id="ds"
                            name="ds"
                            value={month}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => setMonth(event.target.value)}
                        />
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <TextField
                            sx={{ width: '100px' }}
                            margin="normal"
                            size="small"
                            label="Năm"
                            type="text"
                            id="ds"
                            name="ds"
                            value={year}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => setYear(event.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button
                            size="small"
                            sx={{ padding: '7px 10px' }}
                            type="submit"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={checkYear}
                        >
                            Check
                        </Button>
                    </div>
                </form>
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            {dataCustomerID !== false && (
                <ViewCustomrInfo show={showCustomerInfoID} setShow={setShowCustomerInfoID} data={dataCustomerID} />
            )}
            {/* <div>
                <TodoTable rows={report} columns={columns} />
            </div> */}
            <div>Đơn vị: triệu đồng</div>
            <div className={style.table_group}>
                <table>
                    <tr>
                        <th className={style.monthTitle}>Nội dung</th>
                        <th style={{ textAlign: 'center', minWidth: '200px' }}>Total</th>
                        {monthsAR.map((month, index) => (
                            <th key={index} className={style.monthTitle}>
                                {/* <div className={style.sumKH}>{sumMonth.length ? sumMonth[index].quantity : 0}</div> */}
                                {month}
                            </th>
                        ))}
                    </tr>
                    <tr style={{ display: 'none' }}>
                        <td>Thực đạt năm 2023</td>
                        <td>
                            {report.reduce((accumulator, currentValue) => accumulator + currentValue.result2023, 0)}
                        </td>
                        {report.map((dataReport) => {
                            return <td>{convertMoney2(dataReport.result2023)}</td>;
                        })}
                    </tr>

                    <tr>
                        <td>Chỉ tiêu đã phân bổ đầu năm</td>
                        <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) => accumulator + Number(currentValue.target),
                                    0,
                                ),
                            )}
                        </td>
                        {report.map((dataReport) => {
                            return <td>{convertMoney2(dataReport.target)}</td>;
                        })}
                    </tr>

                    <tr>
                        <td>Thực đạt đến tháng hiện tại</td>
                        <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) =>
                                        accumulator + Number(currentValue.total_amount / 1000000),
                                    0,
                                ),
                            )}
                        </td>
                        {report.map((dataReport) => {
                            return <td>{convertMoney2(dataReport.total_amount / 1000000)}</td>;
                        })}
                    </tr>

                    <tr>
                        <td>Doanh thu dự kiến( = Doanh thu thực tế + dự kiến)</td>
                        <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) => accumulator + Number(currentValue.dtdk / 1000000),
                                    0,
                                ),
                            )}
                        </td>
                        {report.map((dataReport) => {
                            return <td>{convertMoney2(dataReport.dtdk / 1000000)}</td>;
                        })}
                    </tr>

                    <tr>
                        <td>Doanh thu công nợ</td>
                        <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) =>
                                        accumulator + Number(currentValue.total_amount_cn / 1000000),
                                    0,
                                ),
                            )}
                        </td>
                        {report.map((dataReport) => {
                            return <td>{convertMoney2(dataReport.total_amount_cn / 1000000)}</td>;
                        })}
                    </tr>

                    <tr>
                        <td>Số còn thừa, thiếu so với chỉ tiêu</td>
                        <td>
                            {convertMoney2(
                                report.reduce((accumulator, currentValue) => {
                                    let kq = checkMonthvalue(
                                        currentValue.report_month,
                                        Number(currentValue.total_amount / 1000000 - currentValue.target),
                                    );
                                    return accumulator + kq;
                                }, 0),
                            )}
                        </td>
                        {report.map((dataReport) => {
                            return (
                                <td>
                                    {convertMoney2(
                                        checkMonthvalue(
                                            dataReport.report_month,
                                            Number(dataReport.total_amount / 1000000 - dataReport.target),
                                        ),
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                    <tr>
                        <td>Số phân bổ thêm vào tháng sau</td>
                        {/* <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) => accumulator + Number(currentValue.allocation),
                                    0,
                                ),
                            )}
                        </td> */}
                        <td>{convertMoney2(allocationAll)}</td>
                        {report.map((dataReport) => {
                            let amountMonth = 13 - getMonthToday();
                            console.log(amountMonth);
                            // return <td>{convertMoney2(dataReport.allocation)}</td>;
                            if (dataReport.report_month >= getMonthToday()) {
                                return <td>{(allocationAll / amountMonth).toFixed(0)}</td>;
                            } else {
                                return <td>0</td>;
                            }
                        })}
                    </tr>
                    <tr>
                        <td>Chỉ tiêu đến tháng hiện tại bao gồm cả số thiếu</td>
                        {/* <td>
                            {convertMoney2(
                                report.reduce(
                                    (accumulator, currentValue) => accumulator + Number(currentValue.allocation),
                                    0,
                                ),
                            )}
                        </td> */}
                        <td>-</td>
                        {report.map((dataReport) => {
                            let amountMonth = 13 - getMonthToday();
                            if (dataReport.report_month >= getMonthToday()) {
                                return (
                                    <td>
                                        {convertMoney2(
                                            Number((allocationAll / amountMonth).toFixed(0)) +
                                                Number(dataReport.target),
                                        )}
                                    </td>
                                );
                            } else {
                                return <td>{convertMoney2(dataReport.target)}</td>;
                            }
                            // return <td>{convertMoney2(dataReport.allocation + dataReport.target)}</td>;
                        })}
                    </tr>
                </table>
            </div>
            <div>
                <TodoTable rows={customerTable} columns={columns} />
            </div>
        </div>
    );
}

export default Month;
