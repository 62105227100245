const typeSelect = [
    {
        value: 'Khách lẻ',
        label: 'Khách lẻ',
    },
    {
        value: 'Khách đoàn',
        label: 'Khách đoàn',
    },
];

const statusSelect = [
    {
        value: 'Đang liên hệ',
        label: 'Đang liên hệ',
    },
    {
        value: 'Đã huỷ',
        label: 'Đã huỷ',
    },
    {
        value: 'Đã liên hệ, báo giá',
        label: 'Đã liên hệ, báo giá',
    },
    {
        value: 'Đã chốt lịch khám',
        label: 'Đã chốt lịch khám',
    },
    {
        value: 'Đã gửi hợp đồng',
        label: 'Đã gửi hợp đồng',
    },
    {
        value: 'Đã cọc tiền',
        label: 'Đã cọc tiền',
    },
    {
        value: 'Đang khám',
        label: 'Đang khám',
    },
    {
        value: 'Trả hồ sơ',
        label: 'Trả hồ sơ',
    },
    {
        value: 'Hoàn thiện hồ sơ quyết toán',
        label: 'Hoàn thiện hồ sơ quyết toán',
    },
    {
        value: 'Hoàn thiện nốt giá trị hợp đồng',
        label: 'Hoàn thiện nốt giá trị hợp đồng',
    },
    {
        value: 'Hoàn thành',
        label: 'Hoàn thành',
    },
];

const potentialSelect = [
    {
        value: 'Done',
        label: 'Done',
    },
    {
        value: 'High',
        label: 'High',
    },
    {
        value: 'Medium',
        label: 'Medium',
    },
    {
        value: 'Low',
        label: 'Low',
    },
];

export const potentialFilter = ['All', 'Done', 'High', 'Medium', 'Low'];

const status_pay = [
    {
        value: 'Doanh thu dự kiến',
        label: 'Doanh thu dự kiến',
    },
    {
        value: 'Công nợ',
        label: 'Công nợ',
    },
    {
        value: 'Doanh thu',
        label: 'Doanh thu',
    },
];

export const infoCustomer = (packSelect) => {
    return [
        { name: 'name', label: 'Tên khách hàng:', type: 'text' },
        { name: 'pid', label: 'Mã PID His', type: 'text' },
        { name: 'contact', label: 'Liên hệ:', type: 'text' },
        { name: 'note', label: 'Ghi chú:', type: 'text' },
        { name: 'source', label: 'Nguồn', type: 'text' },
        { name: 'type', label: 'Loại', type: 'select', select: typeSelect, defaultSelect: typeSelect[0].value },
        {
            name: 'status',
            label: 'Trạng thái',
            type: 'select',
            select: statusSelect,
            defaultSelect: statusSelect[0].value,
        },
        {
            name: 'package',
            label: 'Đăng kí gói(Mã gói):',
            type: 'select',
            select: packSelect,
            defaultSelect: packSelect[0].value,
        },
        { name: 'date_ex', label: 'Ngày khám:', type: 'date' },

        { name: 'date_pay', label: 'Ngày thanh toán (Ngày hẹn):', type: 'date' },
        { name: 'pay', label: 'Doanh thu', type: 'text', format: 'thousand' },
        {
            name: 'status_pay',
            label: 'Loại Doanh thu',
            type: 'select',
            select: status_pay,
            defaultSelect: status_pay[0].value,
        },
    ];
};
