import { useState, useEffect } from 'react';
import style from './Warning.module.scss';
import { NavLink } from 'react-router-dom';
import { procedureApi } from '~/api/procedure/procedure';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import Swal from 'sweetalert2';
import adminApi from '~/api/admin/admin.js';
import { convertMoney, convertDate } from '~/tool/convert';
import CheckPotential from '~/pages/compoents/CheckPotential/CheckPotential';

function Warning() {
    const [dataProcedure, setDataProcedure] = useState([]);
    useEffect(() => {
        getProcedure();
    }, []);

    const getProcedure = async () => {
        // const dataTableNew = await procedureApi.getProcedure();
        const dataTableNew = await adminApi.getWarningPay();

        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setDataProcedure(dataStt);
    };

    const columns = [
        // {
        //     field: 'customerID',
        //     headerName: 'Xem',
        //     maxWidth: 30,
        //     renderCell: (params) => (
        //         <WysiwygIcon
        //             onClick={() => viewCustomrID(params.row)}
        //             style={{ cursor: 'pointer', marginRight: '8px' }}
        //         />
        //     ),
        // },
        {
            field: 'stt',
            headerName: '.STT',
            maxWidth: 30,
        },
        {
            field: 'name',
            headerName: '.Khách hàng',
            minWidth: 250,
        },
        {
            field: 'potential',
            headerName: 'Tiềm năng',
            minWidth: 60,
            renderCell: (params) => <CheckPotential potential={params.row.potential} />,
        },
        { field: 'status', headerName: 'Trạng thái', minWidth: 150 },
        {
            field: 'status_pay',
            headerName: 'Số tiền (₫)',
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#28529a' }}>
                        {convertMoney(params.row.pay)} ₫
                    </div>
                    <div style={{ fontSize: '10px' }}>{params.row.status_pay}</div>
                </div>
            ),
        },
        {
            field: 'date_pay',
            headerName: '.Ngày thanh toán',
            minWidth: 150,
            renderCell: (params) => <div style={{ color: params.row.color }}>{params.row.date_pay}</div>,
        },
        // {
        //     field: 'input',
        //     headerName: '.Ngày cập nhật',
        //     minWidth: 150,
        // },
        {
            field: 'date_ex',
            headerName: 'Ngày khám',
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: '.Loại',
            minWidth: 150,
        },
        { field: 'package', headerName: 'Gói', minWidth: 150 },
        // { field: 'expected', headerName: 'Dự kiến (Đã bỏ)', minWidth: 150 },

        { field: 'contact', headerName: 'Liên hệ', minWidth: 150 },
        { field: 'source', headerName: 'Nguồn', minWidth: 150 },
        { field: 'note', headerName: 'Ghi chú', minWidth: 150 },
    ];
    return (
        <div>
            <div>
                <TodoTable rows={dataProcedure} columns={columns} />
            </div>
        </div>
    );
}

export default Warning;
