import { TextField, Button, Dialog } from '@mui/material';
import style from './ViewCustomerInfo.module.scss';
import { convertMoney, convertDate } from '~/tool/convert';
import DeleteIcon from '@mui/icons-material/Delete';
import customerApi from '~/api/customer/customer';
import Swal from 'sweetalert2';
import { useState } from 'react';

function ViewCustomrInfo({ show, setShow, data, actionsDisplay }) {
    const [remove, setRemove] = useState(0);
    console.log(data);
    const handleClose = () => {
        setShow(false);
    };
    var removeActions = async (value, index) => {
        console.log(value);
        const { isConfirmed } = await Swal.fire({
            title: 'Xoá lần liên hệ này?',
            text: value.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await customerApi.removeCustomerActions(value);
            setShow(false);
            data.actions.splice(index, 1);
            if (resultRemove.result) {
                Swal.fire('Đã xoá lần liên hệ này', value.date_actions, 'success');
                // getCustomer();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };

    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div style={{ padding: '20px', minWidth: '500px' }}>
                <div className={style.table}>
                    <table>
                        <tr>
                            <td>Thông tin khách hàng:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>PID:</td>
                            <td>{data.info.pid}</td>
                        </tr>
                        <tr>
                            <td>Tên:</td>
                            <td>{data.info.name}</td>
                        </tr>
                        <tr>
                            <td>Liên hệ:</td>
                            <td>{data.info.contact}</td>
                        </tr>
                        <tr>
                            <td>Ghi chú:</td>
                            <td>{data.info.note}</td>
                        </tr>
                        <tr>
                            <td>Nguồn:</td>
                            <td>{data.info.source}</td>
                        </tr>
                    </table>
                </div>

                <div className={style.table}>
                    <table>
                        <tr>
                            <td>Thông tin thanh toán:</td>
                            <td></td>
                        </tr>
                        {data.pay.map((value) => (
                            <tr>
                                <td>
                                    <p>{convertDate(value.date_pay)}</p>
                                    <p style={{ fontSize: '10px' }}>{value.note}</p>
                                </td>
                                <td>
                                    <p>{convertMoney(value.amount)}</p>
                                    <p style={{ fontSize: '10px' }}>{value.status_pay}</p>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                {data.info.type === 'Khách đoàn' && (
                    <div className={style.table}>
                        <table>
                            <tr>
                                <td>Thông tin khách đoàn:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Thông tin pháp nhân:</td>
                                <td>{data.info.legal}</td>
                            </tr>
                            <tr>
                                <td>Số lượng cán bộ:</td>
                                <td>{data.info.quantity}</td>
                            </tr>
                            <tr>
                                <td>Ngân sách và danh mục:</td>
                                <td>{data.info.budget}</td>
                            </tr>
                            <tr>
                                <td>Chốt hợp đồng:</td>
                                <td>{data.info.contract}</td>
                            </tr>
                        </table>
                    </div>
                )}

                <div className={style.table}>
                    <table>
                        <tr>
                            <td>Thông tin khám:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Loại khách hàng:</td>
                            <td>{data.info.type}</td>
                        </tr>
                        <tr>
                            <td>Trạng thái</td>
                            <td>{data.info.status}</td>
                        </tr>
                        <tr>
                            <td>Mã gói:</td>
                            <td>{data.info.package}</td>
                        </tr>
                        <tr>
                            <td>Ngày khám:</td>
                            <td>{convertDate(data.info.date_ex)}</td>
                        </tr>
                    </table>
                </div>

                <div className={style.table}>
                    <table>
                        <tr>
                            <td>Thông tin liên hệ:</td>
                            <td></td>
                        </tr>
                        {data.actions.map((value, index) => (
                            <tr>
                                <td>
                                    <p className={style.actions}>
                                        {!actionsDisplay && (
                                            <div>
                                                <DeleteIcon
                                                    data={value}
                                                    style={{}}
                                                    onClick={() => {
                                                        removeActions(value, index);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div>{convertDate(value.date_actions)}</div>
                                    </p>
                                </td>
                                <td>
                                    <p>{value.note}</p>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </Dialog>
    );
}

export default ViewCustomrInfo;
