import * as yup from 'yup';
export const yupProcedure = yup.object().shape({
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
    link: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const sampleProcedure = [
    { name: 'name', label: 'Tên Quy trình', type: 'text' },
    { name: 'description', label: 'Mô tả', type: 'text' },
    { name: 'link', label: 'Đường link', type: 'text' },
];
