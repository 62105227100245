// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Changepassword_body__uM6fS {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.Changepassword_formLogin__DYx9K {
  width: 500px;
  padding: 50px;
}

.Changepassword_notification__YSm9r {
  margin-top: 10px;
  color: red;
}

.Changepassword_login__LbAg2 {
  cursor: pointer;
  direction: none;
  margin: 10px 0px;
}

.Changepassword_login__LbAg2 > a {
  text-decoration: none;
  color: #1976d2;
}

.Changepassword_passwordDiv__DFrtu {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Auth/ChangePassword/Changepassword.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,eAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".body {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.formLogin {\n    width: 500px;\n    padding: 50px;\n}\n\n.notification {\n    margin-top: 10px;\n    color: red;\n}\n\n.login {\n    cursor: pointer;\n    direction: none;\n    margin: 10px 0px;\n}\n\n.login > a {\n    text-decoration: none;\n    color: #1976d2;\n}\n\n.passwordDiv {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Changepassword_body__uM6fS`,
	"formLogin": `Changepassword_formLogin__DYx9K`,
	"notification": `Changepassword_notification__YSm9r`,
	"login": `Changepassword_login__LbAg2`,
	"passwordDiv": `Changepassword_passwordDiv__DFrtu`
};
export default ___CSS_LOADER_EXPORT___;
