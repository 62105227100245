export const selectStatus = [
    {
        value: 'Cập nhật thông tin',
        label: 'Cập nhật thông tin',
    },
    {
        value: 'Ốp kí hợp đồng',
        label: 'Ốp kí hợp đồng',
    },
    {
        value: 'Tổ chức khám',
        label: 'Tổ chức khám',
    },
    {
        value: 'Trả hồ sơ thanh lý',
        label: 'Trả hồ sơ thanh lý',
    },
];
