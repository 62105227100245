import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

export default function Search({ setDataSearch, dataSearch }) {
    const handleSearch = (e) => {
        setDataSearch(e.target.value);
    };

    return (
        <div
            style={{
                backgroundColor: '#ffffff',
                width: '250px',
                borderRadius: '10px',
                padding: '2px',
                border: '1px solid rgb(210, 214, 218)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <SearchIcon sx={{ color: 'rgb(52, 71, 103)', margin: '0px 6px' }} />
            <InputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearch}
                value={dataSearch}
            />
        </div>
    );
}
