import { TextField, Button, Dialog } from '@mui/material';
import style from './Report.module.scss';
import { convertMoney, convertDate } from '~/tool/convert';
import TextDate from '~/compoents/TextDate/TextDate';
import { useState, useEffect } from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import customerApi from '~/api/customer/customer';
import adminApi from '~/api/admin/admin';
import { convertNull } from '~/tool/convert';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CheckCustomer from '~/compoents/CheckCustomer/CheckCustomer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { Flare } from '@mui/icons-material';

function ReportAdmin({ show, setShow, dataReport, load, reportID, getReport }) {
    const [beginDs, setBeginDs] = useState(dataReport.beginDs);
    const [beginDk, setBeginDk] = useState(dataReport.beginDk);

    const [endDs, setEndDs] = useState(dataReport.endDs);
    const [endDk, setEndDk] = useState(dataReport.endDk);

    const [gt, setGt] = useState(dataReport.gt);
    const [gp, setGp] = useState(dataReport.gp);

    const [dataDs, setDataDs] = useState([]);
    const [dataDk, setDataDk] = useState([]);

    const [loading, setLoading] = useState(false);

    const [checkView, setcheckView] = useState(false);
    const [dataCustomerCheck, setdataCustomerCheck] = useState([]);
    const [customerKHDK, setCustomerKHDK] = useState([]);
    const [noteAdmin, setNoteAdmin] = useState(dataReport.noteAdmin);

    useEffect(() => {
        console.log(reportID);
        if (reportID) {
            handleCheckDs();
            handleCheckDk();
        }
    }, [reportID]);

    const checkCustomerds = async (dt, type, potential) => {
        // alert(dt + '-' + type + '-' + potential);
        const dataTableNew = await adminApi.getCheckCustomer({
            dt,
            type,
            potential,
            beginDs,
            beginDk,
            endDs,
            endDk,
            sale: dataReport.pic,
        });

        if (dataTableNew.length) {
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            setdataCustomerCheck(dataStt);
            setcheckView(true);
        } else {
            setdataCustomerCheck([]);
            setcheckView(true);
        }
        console.log(dataTableNew);
    };

    const handleCheckDs = async () => {
        setLoading(true);
        const data = await adminApi.getReportDs({
            beginDs,
            endDs,
            sale: dataReport.pic,
        });
        console.log(data.potential);
        if (data) {
            setDataDs(data);
            setLoading(false);
        }
    };
    const handleCheckDk = async () => {
        setLoading(true);
        const data = await adminApi.getReportDk({
            beginDk,
            endDk,
            sale: dataReport.pic,
            reportID,
        });
        if (data) {
            setDataDk(data);
            setLoading(false);
            setCustomerKHDK(data.dataCustomerKHDK);
        }
    };

    const handleClose = () => {
        setShow(false);
    };
    const setApprove = async (data) => {
        const check = await adminApi.setApproveReport({ approve: data, reportID, noteAdmin });
        if (check.result) {
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            handleCheckDk();
        } else {
            Swal.fire({
                icon: 'error',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
        }

        getReport();
    };
    return (
        <Dialog
            maxWidth={false}
            open={show}
            onClose={handleClose}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            {checkView && <CheckCustomer customerTable={dataCustomerCheck} show={checkView} setShow={setcheckView} />}
            {loading && (
                <Box sx={{ width: '100%', position: 'sticky', top: 0 }}>
                    <LinearProgress />
                </Box>
            )}

            <div className={style.reportAll}>
                <h3>Nhân viên: {dataReport.pic}</h3>

                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="6" style={{ textAlign: 'center' }}>
                                KH từ đầu tháng
                            </td>
                        </tr>
                        <tr rowspan="2" className={style.backColor}>
                            <td className={style.tdBegin}>Doanh thu từ đầu tháng</td>
                            <td className={style.tdBegin}>Công nợ từ đầu tháng</td>
                            <td>%</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDs.dst))} ₫
                            </td>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDs.debt))} ₫
                            </td>
                            <td rowspan="2" className={style.money}>
                                {dataDs.dst ? Math.round((dataDs.dst * 100) / 1000000000) : 0}
                            </td>
                            <td>Lẻ</td>
                            {dataDs.potentialMonth && dataDs.potentialMonth.length > 0
                                ? dataDs.potentialMonth.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dst', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDs.potentialMonth && dataDs.potentialMonth.length > 0
                                ? dataDs.potentialMonth.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dst', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                    </table>
                </div>
                <h2 style={{ margin: '10px', textAlign: 'center' }}>Báo cáo tuần</h2>

                <h3>I. Kết quả tuần:</h3>
                <div className={style.timeSet}>
                    <TextDate label="Từ ngày" value={beginDs} setValue={setBeginDs}></TextDate> &nbsp; &nbsp;
                    <TextDate label="Đến ngày" value={endDs} setValue={setEndDs}></TextDate> &nbsp; &nbsp;
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            sx={{ padding: '7px 20px' }}
                            type="submit"
                            size="small"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={handleCheckDs}
                        >
                            Check
                        </Button>
                    </div>
                </div>
                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"> </td>
                            <td colspan="6" style={{ textAlign: 'center' }}>
                                KH đạt được
                            </td>
                        </tr>
                        <tr className={style.backColor}>
                            <td className={style.tdBegin}>Doanh thu thực tế</td>
                            <td className={style.tdBegin}>SL Khách đã liên hệ</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td
                                rowspan="2"
                                className={style.money}
                                onClick={() => checkCustomerds('dsct', 'All', 'No')}
                            >
                                {convertMoney(convertNull(dataDs.ds))} ₫
                            </td>
                            <td
                                rowspan="2"
                                className={style.money}
                                onClick={() => checkCustomerds('meet', 'All', 'No')}
                            >
                                {dataDs.infoMeet}
                            </td>
                            <td>Lẻ</td>
                            {dataDs.potential && dataDs.potential.length > 0
                                ? dataDs.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('ds', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDs.potential && dataDs.potential.length > 0
                                ? dataDs.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('ds', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td colspan="6">
                                <TextField
                                    fullWidth
                                    label="Thông tin"
                                    multiline
                                    maxRows={5}
                                    variant="standard"
                                    value={gt}
                                    onChange={(event) => setGt(event.target.value)}
                                    inputProps={{ style: { fontSize: 12 } }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                <h3 style={{ marginTop: '20px' }}> II. Kế hoạch tuần tiếp theo</h3>
                <div className={style.timeSet}>
                    <TextDate label="Từ ngày" value={beginDk} setValue={setBeginDk}></TextDate> &nbsp; &nbsp;
                    <TextDate label="Đến ngày" value={endDk} setValue={setEndDk}></TextDate> &nbsp; &nbsp;
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            sx={{ padding: '7px 20px' }}
                            type="submit"
                            size="small"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={handleCheckDk}
                        >
                            Check
                        </Button>
                    </div>
                </div>
                <div className={style.table}>
                    <table>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="5" style={{ textAlign: 'center' }}>
                                KH Dự kiến
                            </td>
                        </tr>
                        <tr className={style.backColor}>
                            <td className={style.tdBegin}>Doanh số dự kiến</td>
                            <td>Loại</td>
                            <td>Done</td>
                            <td>High</td>
                            <td>Medium</td>
                            <td>Low</td>
                        </tr>
                        <tr>
                            <td rowspan="2" className={style.money}>
                                {convertMoney(convertNull(dataDk.ds))} ₫
                            </td>
                            <td>Lẻ</td>
                            {dataDk.potential && dataDk.potential.length > 0
                                ? dataDk.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dk', 'Khách lẻ', index)} key={index}>
                                          {value.value}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td>Đoàn</td>
                            {dataDk.potential && dataDk.potential.length > 0
                                ? dataDk.potential.map((value, index) => (
                                      <td onClick={() => checkCustomerds('dk', 'Khách đoàn', index)} key={index}>
                                          {value.value_group}
                                      </td>
                                  ))
                                : ''}
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div>
                                    <h3>Kế hoạch khách hiện hữu:</h3>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    {customerKHDK.length > 0 && (
                                        <table>
                                            <tr>
                                                <th>Khách hàng</th>
                                                <th>Kế hoạch</th>
                                                <th></th>
                                            </tr>
                                            {customerKHDK.map((khdk, index) => (
                                                <tr>
                                                    <td>{khdk.customerName}</td>
                                                    <td>{khdk.status}</td>
                                                    <td>{khdk.note}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <TextField
                                    fullWidth
                                    label="Đề xuất giải pháp"
                                    value={gp}
                                    onChange={(event) => setGp(event.target.value)}
                                    multiline
                                    maxRows={5}
                                    variant="standard"
                                    inputProps={{ style: { fontSize: 12 } }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                <div className={style.evaluate}>
                    <h3 style={{ backgroundColor: '#f1f1f1', padding: '10px' }}>Đánh giá:</h3>

                    <div>
                        <TextField
                            fullWidth
                            label="Yêu cầu chỉnh sửa báo cáo"
                            value={noteAdmin}
                            onChange={(event) => setNoteAdmin(event.target.value)}
                            multiline
                            rows={2}
                            maxRows={10}
                            variant="standard"
                            inputProps={{ style: { fontSize: 12 } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <div className={style.xacnhan}>
                            <Button
                                sx={{ padding: '7px 20px' }}
                                type="submit"
                                size="small"
                                variant="contained"
                                component="label"
                                startIcon={<CheckCircleIcon />}
                                onClick={() => {
                                    setApprove('Done');
                                }}
                            >
                                Done
                            </Button>
                        </div>
                        <div className={style.xacnhan}>
                            <Button
                                sx={{ padding: '7px 20px' }}
                                type="submit"
                                size="small"
                                variant="contained"
                                component="label"
                                startIcon={<CancelPresentationIcon />}
                                onClick={() => {
                                    setApprove('Fail');
                                }}
                            >
                                Fail
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default ReportAdmin;
