import { useState, useEffect } from 'react';
import TodoTable from '~/compoents/TodoTable/TodoTable';
import { convertMoney } from '~/tool/convert';
import adminApi from '~/api/admin/admin.js';
import style from './Statistical.module.scss';
import { getMonthBeginEnd } from '~/tool/timeToday';
import { Button, TextField } from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextDate from '~/compoents/TextDate/TextDate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { convertDate } from '~/tool/convert';
import Swal from 'sweetalert2';

import Report from '../compoents/Report/Report';
import customerApi from '~/api/customer/customer';

function Week() {
    const [showAddWeek, setShowAddWeek] = useState(false);
    const [dataReport, setDataReport] = useState([]);
    const [reportID, setReportID] = useState(0);
    const [dataRp, setDataRp] = useState({
        beginDs: '',
        endDs: '',
        beginDk: '',
        endDk: '',
        gt: '',
        gp: '',
    });

    const getReport = async () => {
        try {
            const dataTableNew = await customerApi.getReport();
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            console.log(dataStt);
            setDataReport(dataStt);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getReport();
    }, []);

    const addReport = () => {
        setReportID(0);
        setDataRp({
            beginDs: '',
            endDs: '',
            beginDk: '',
            endDk: '',
            gt: '',
            gp: '',
        });
        setShowAddWeek(true);
    };

    const viewReport = async (data) => {
        console.log(data);
        setReportID(data.reportID);
        setDataRp(data);
        setDataRp(data);
        setShowAddWeek(true);
    };
    const removeReport = async (data) => {
        console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Bạn có muốn xoá báo cáo này?',
            text: data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await customerApi.removeReport(data);
            console.log(resultRemove);
            if (resultRemove.result) {
                Swal.fire(resultRemove.noti, 'success');
                getReport();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };

    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 1 },
        {
            field: 'beginDs',
            headerName: 'Ngày bắt đầu',
            minWidth: 150,
            flex: 2,
            renderCell: (params) => convertDate(params.row.beingDs),
        },
        {
            field: 'endDs',
            headerName: 'Ngày kết thúc',
            minWidth: 150,
            flex: 2,
            renderCell: (params) => convertDate(params.row.endDs),
        },
        {
            field: 'approve',
            headerName: 'Phê duyệt',
            minWidth: 150,
            flex: 2,
            // renderCell: (params) => convertDate(params.row.approve),
        },
        {
            field: 'Actions',
            headerName: 'Tuỳ chỉnh',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <ContentPasteSearchIcon
                        onClick={() => viewReport(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removeReport(params.row)} style={{ cursor: 'pointer' }} />
                </div>
            ),
        },
    ];
    return (
        <div>
            <div>
                {showAddWeek && (
                    <Report
                        show={showAddWeek}
                        setShow={setShowAddWeek}
                        dataReport={dataRp}
                        load={getReport}
                        reportID={reportID}
                    />
                )}
            </div>
            <div>
                <div style={{ marginTop: '5px' }}>
                    <Button
                        sx={{ padding: '7px 20px' }}
                        type="submit"
                        size="small"
                        variant="contained"
                        component="label"
                        startIcon={<AssignmentIcon />}
                        onClick={addReport}
                    >
                        Thêm báo cáo
                    </Button>
                </div>
            </div>
            <div>
                <TodoTable rows={dataReport} columns={columns} />
            </div>
        </div>
    );
}

export default Week;
