import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function TextDate({ label, value, setValue }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateField', 'DateField']}>
                <DatePicker
                    margin="normal"
                    slotProps={{ textField: { size: 'small', margin: 'normal' } }}
                    style={{ width: '200px' }}
                    label={label}
                    value={value ? dayjs(value) : null}
                    format="DD-MM-YYYY"
                    onChange={(newDate) => {
                        const formattedDate = dayjs(newDate).format('YYYY-MM-DD'); // hoặc định dạng khác nếu cần
                        setValue(formattedDate);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
