import { useState, useEffect } from 'react';
import TodoTable from '~/compoents/TodoTable/TodoTable';
import { convertMoney } from '~/tool/convert';
import adminApi from '~/api/admin/admin.js';
import style from './Statistical.module.scss';
import { getMonthBeginEnd } from '~/tool/timeToday';
import { Button, TextField } from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextDate from '~/compoents/TextDate/TextDate';

function Total() {
    const [dateBegin, setDateBegin] = useState(localStorage.getItem('dateBegin'));
    const [dateEnd, setDateEnd] = useState(localStorage.getItem('dateEnd'));
    const [customerTable, setCustomerTable] = useState([]);
    const [userSelect, setUserSelect] = useState('Tất cả');
    const [dataUser, setDataUser] = useState(['Tất cả']);

    const getCustomer = async () => {
        const dataTableNew = await adminApi.getPackInfo({
            dateBegin: localStorage.getItem('dateBegin'),
            dateEnd: localStorage.getItem('dateEnd'),
            sale: userSelect,
        });
        console.log(dataTableNew);
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setCustomerTable(dataStt);
    };

    const getDataUser = async () => {
        const dataUserAPI = await adminApi.getDataUser();
        let dataArray = dataUser;
        dataUserAPI.map((value) => {
            dataArray.push(value.account);
        });

        setDataUser(dataArray);
        console.log(dataUser);
    };
    useEffect(() => {
        getCustomer();
        getDataUser();
    }, []);

    const handleCheck = (event) => {
        event.preventDefault();
        localStorage.setItem('dateBegin', dateBegin);
        localStorage.setItem('dateEnd', dateEnd);
        getCustomer();
    };

    const handleCheckMonthNow = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin(monthNow.firstDay);
        setDateEnd(monthNow.lastDay);
        localStorage.setItem('dateBegin', monthNow.firstDay);
        localStorage.setItem('dateEnd', monthNow.lastDay);
        getCustomer();
    };

    const columns = [
        { field: 'stt', headerName: 'STT', flex: 3 },
        { field: 'package', headerName: 'Tên gói', flex: 5 },
        { field: 'Done', headerName: 'Done', flex: 5 },
        { field: 'High', headerName: 'High', flex: 5 },
        { field: 'Medium', headerName: 'Medium', flex: 5 },
        { field: 'Low', headerName: 'Low', flex: 5 },
    ];

    return (
        <div>
            <div className={style.setting}>
                <div>
                    <form onSubmit={handleCheck} className={style.check}>
                        <div>
                            <TextDate label="Từ ngày" value={dateBegin} setValue={setDateBegin}></TextDate>

                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Từ ngày"
                                type="date"
                                id="dateBegin"
                                name="dateBegin"
                                value={dateBegin}
                                onChange={(event) => setDateBegin(event.target.value)}
                            /> */}
                        </div>
                        <div>
                            <TextDate label="Đến ngày" value={dateEnd} setValue={setDateEnd}></TextDate>
                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Đến ngày"
                                type="date"
                                id="dateEnd"
                                name="dateEnd"
                                value={dateEnd}
                                onChange={(event) => setDateEnd(event.target.value)}
                            /> */}
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Select
                                sx={{ width: '100px' }}
                                id="demo-simple-select"
                                size="small"
                                value={userSelect}
                                onChange={(event) => setUserSelect(event.target.value)}
                            >
                                {dataUser.map((value) => (
                                    <MenuItem value={value} key={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div style={{ marginTop: '5px' }}>
                            <Button
                                sx={{ padding: '7px 20px' }}
                                type="submit"
                                size="small"
                                variant="contained"
                                component="label"
                                startIcon={<ContentPasteSearchIcon />}
                                onClick={handleCheck}
                            >
                                Check
                            </Button>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                size="small"
                                sx={{ padding: '7px 10px' }}
                                type="submit"
                                variant="contained"
                                component="label"
                                startIcon={<AccessTimeFilledIcon />}
                                onClick={handleCheckMonthNow}
                            >
                                Tháng này
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <TodoTable rows={customerTable} columns={columns} height="80vh" />
            </div>
        </div>
    );
}

export default Total;
