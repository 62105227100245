import style from './DivDblclick.module.scss';

function DivDblclick({ content, funDbClick, data }) {
    return (
        <div
            style={{
                whiteSpace: 'pre-line',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
            }}
            className={style.info}
            onDoubleClick={() => funDbClick(data)}
        >
            {content}
        </div>
    );
}

export default DivDblclick;
