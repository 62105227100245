import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import style from './TodoTable.module.scss';

// columnsAdd Trường dữ liệu cột
// rows: trường dữ liệu
const MyTable = ({ columns, rows, height }) => {
    return (
        <div>
            <div className={style.todoTable} style={{ height: height }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                />
            </div>
        </div>
    );
};

export default MyTable;
