// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultLayout_content__e9Pwb {
  padding-left: 212px;
}

.DefaultLayout_content_info__nkiyk {
  min-width: 500px;
  margin: 5px;
  padding: 20px 30px;
}

@media only screen and (max-width: 1200px) {
  .DefaultLayout_content__e9Pwb {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 100px;
  }
}
@media only screen and (max-width: 800px) {
  .DefaultLayout_content__e9Pwb {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 90px;
  }
  .DefaultLayout_content_info__nkiyk {
    padding-top: 0px;
    padding: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/DefaultLayout/DefaultLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI;IACI,iBAAA;IACA,kBAAA;IACA,kBAAA;EACN;AACF;AAEA;EACI;IACI,iBAAA;IACA,kBAAA;IACA,iBAAA;EAAN;EAGE;IACI,gBAAA;IACA,aAAA;EADN;AACF","sourcesContent":[".content {\n    padding-left: 212px;\n}\n\n.content_info {\n    min-width: 500px;\n    margin: 5px;\n    padding: 20px 30px;\n}\n\n@media only screen and (max-width: 1200px) {\n    .content {\n        padding-left: 5px;\n        padding-right: 5px;\n        padding-top: 100px;\n    }\n}\n\n@media only screen and (max-width: 800px) {\n    .content {\n        padding-left: 0px;\n        padding-right: 0px;\n        padding-top: 90px;\n    }\n\n    .content_info {\n        padding-top: 0px;\n        padding: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `DefaultLayout_content__e9Pwb`,
	"content_info": `DefaultLayout_content_info__nkiyk`
};
export default ___CSS_LOADER_EXPORT___;
