// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DivDblclick_info__Q7tZ5 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/compoents/DivDblclick/DivDblclick.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,qBAAA;AACJ","sourcesContent":[".info {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-self: flex-start;\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `DivDblclick_info__Q7tZ5`
};
export default ___CSS_LOADER_EXPORT___;
