import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import { useState, useEffect } from 'react';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import customerApi from '~/api/customer/customer';
import adminApi from '~/api/admin/admin';
import CheckPotential from '~/pages/compoents/CheckPotential/CheckPotential';
import { convertMoney, convertDate } from '~/tool/convert';
import TextDate from '~/compoents/TextDate/TextDate';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Button, TextField } from '@mui/material';
import { getMonthBeginEnd } from '~/tool/timeToday';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import style from './Customer.module.scss';

function CustomerAdmin() {
    const [customerTable, setCustomerTable] = useState([]);
    const [dataCustomerID, setDataCustomerID] = useState(false);
    const [showCustomerInfoID, setShowCustomerInfoID] = useState(false);
    const [dataSearch, setDataSearch] = useState('');
    const [customerInfo, setCustomerInfo] = useState([]);
    const [dateEnd, setDateEnd] = useState(localStorage.getItem('dateEnd'));
    const [dateBegin, setDateBegin] = useState(localStorage.getItem('dateBegin'));

    const handleCheck = (event) => {
        event.preventDefault();
        localStorage.setItem('dateBegin', dateBegin);
        localStorage.setItem('dateEnd', dateEnd);
        getCustomer('date');
    };

    const handleCheckNull = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin('');
        setDateEnd('');
        localStorage.removeItem('dateBegin');
        localStorage.removeItem('dateEnd');
        getCustomer('date');
    };
    const handleCheckMonthNow = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin(monthNow.firstDay);
        setDateEnd(monthNow.lastDay);
        localStorage.setItem('dateBegin', monthNow.firstDay);
        localStorage.setItem('dateEnd', monthNow.lastDay);
        getCustomer('date');
    };

    const viewCustomrID = async (data) => {
        const dataID = await customerApi.getCustomerIDInfo({ customerID: data.customerID });
        setDataCustomerID(dataID);
        setShowCustomerInfoID(true);
    };

    useEffect(() => {
        console.log('lol');
        getCustomer();
    }, []);

    const getCustomer = async (check) => {
        console.log('check');
        var dataTableNew;
        // try {
        if (!dataSearch || check === 'date') {
            setDataSearch('');
            dataTableNew = await adminApi.getCustomerAll({
                dateBegin: localStorage.getItem('dateBegin'),
                dateEnd: localStorage.getItem('dateEnd'),
            });
        } else {
            dataTableNew = await customerApi.getCustomerSearch({
                key: dataSearch,
            });
        }

        if (dataTableNew.length) {
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            setCustomerTable(dataStt);
        } else {
            setCustomerTable([]);
        }
        const dataInfo = await customerApi.getCustomerInfo({
            dateBegin: localStorage.getItem('dateBegin'),
            dateEnd: localStorage.getItem('dateEnd'),
        });
        setCustomerInfo(dataInfo[0]);
        // }
        // catch (error) {
        //     setCustomerTable([]);
        // }
    };

    const columns = [
        {
            field: 'customerID',
            headerName: 'Xem',
            maxWidth: 30,
            renderCell: (params) => (
                <WysiwygIcon
                    onClick={() => viewCustomrID(params.row)}
                    style={{ cursor: 'pointer', marginRight: '8px' }}
                />
            ),
        },
        {
            field: 'stt',
            headerName: '.STT',
            maxWidth: 30,
        },
        {
            field: 'name',
            headerName: '.Khách hàng',
            minWidth: 250,
        },
        {
            field: 'potential',
            headerName: 'Tiềm năng',
            minWidth: 60,
            renderCell: (params) => <CheckPotential potential={params.row.potential} />,
        },
        { field: 'status', headerName: 'Trạng thái', minWidth: 150 },
        {
            field: 'status_pay',
            headerName: 'Số tiền (₫)',
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#28529a' }}>
                        {convertMoney(params.row.pay)} ₫
                    </div>
                    <div style={{ fontSize: '10px' }}>{params.row.status_pay}</div>
                </div>
            ),
        },
        {
            field: 'date_pay',
            headerName: '.Ngày thanh toán',
            minWidth: 150,
            renderCell: (params) => <div style={{ color: 'rgb(40, 82, 154)' }}>{params.row.date_pay}</div>,
        },
        // {
        //     field: 'input',
        //     headerName: '.Ngày cập nhật',
        //     minWidth: 150,
        // },
        {
            field: 'date_ex',
            headerName: 'Ngày khám',
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: '.Loại',
            minWidth: 150,
        },
        { field: 'package', headerName: 'Gói', minWidth: 150 },
        // { field: 'expected', headerName: 'Dự kiến (Đã bỏ)', minWidth: 150 },

        { field: 'contact', headerName: 'Liên hệ', minWidth: 150 },
        { field: 'source', headerName: 'Nguồn', minWidth: 150 },
        { field: 'note', headerName: 'Ghi chú', minWidth: 150 },
    ];
    return (
        <div>
            <div>
                <form onSubmit={handleCheck} className={style.check}>
                    <div>
                        <TextDate label="Từ ngày" value={dateBegin} setValue={setDateBegin}></TextDate>
                    </div>
                    <div>
                        <TextDate label="Đến ngày" value={dateEnd} setValue={setDateEnd}></TextDate>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button
                            size="small"
                            sx={{ padding: '7px 10px' }}
                            type="submit"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={handleCheck}
                        >
                            Check
                        </Button>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button
                            size="small"
                            sx={{ padding: '7px 10px' }}
                            type="submit"
                            variant="contained"
                            component="label"
                            startIcon={<AccessTimeFilledIcon />}
                            onClick={handleCheckMonthNow}
                        >
                            Tháng này
                        </Button>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button
                            size="small"
                            sx={{ padding: '7px 10px' }}
                            type="submit"
                            variant="contained"
                            component="label"
                            startIcon={<SearchOffIcon />}
                            onClick={handleCheckNull}
                        >
                            NULL
                        </Button>
                    </div>
                </form>
            </div>
            <div>
                <TodoTable rows={customerTable} columns={columns} />
            </div>
        </div>
    );
}

export default CustomerAdmin;
