import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '~/layouts/components/Header';
import { Sidebar } from '~/layouts/components/Sidebar';
import styles from '~/layouts/DefaultLayout/DefaultLayout.module.scss';
import authApi from '~/api/auth';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';

const rule = {
    sale: ['/customer', '/procedureinfo', '/paperinfo', '/week', '/month', '/group'],
    admin: [
        '/dashboard',
        '/statistical',
        '/procedure',
        '/total',
        '/paper',
        '/weekadmin',
        '/monthadmin',
        '/warning',
        '/groupadmin',
        '/customeradmin',
    ],
};

const menuOrigin = {
    admin: [
        { link: '/dashboard', content: 'Dashboard', icon: SpaceDashboardIcon },
        { link: '/statistical', content: 'KPI', icon: SpaceDashboardIcon },
        { link: '/customeradmin', content: 'DS khách hàng', icon: SpaceDashboardIcon },
        // { link: '/total', content: 'Tổng hợp', icon: SpaceDashboardIcon },
        { link: '/weekadmin', content: 'Báo cáo tuần', icon: SpaceDashboardIcon },
        { link: '/monthadmin', content: 'Báo cáo tháng', icon: SpaceDashboardIcon },
        { link: '/groupadmin', content: 'Lịch khám đoàn', icon: PermContactCalendarIcon },
        { link: '/warning', content: 'Cảnh báo thanh toán đoàn', icon: SpaceDashboardIcon },
        { link: '/procedure', content: 'Quy trình', icon: SpaceDashboardIcon },
        { link: '/paper', content: 'Tờ trình', icon: SpaceDashboardIcon },
    ],
    sale: [
        { link: '/customer', content: 'QL Khách hàng', icon: PermContactCalendarIcon },
        { link: '/group', content: 'Lịch khám đoàn', icon: PermContactCalendarIcon },
        { link: '/week', content: 'Báo cáo tuần', icon: PermContactCalendarIcon },
        { link: '/month', content: 'Báo cáo tháng', icon: PermContactCalendarIcon },
        { link: '/procedureinfo', content: 'Quy trình', icon: PermContactCalendarIcon },
        { link: '/paperinfo', content: 'Tờ trình', icon: PermContactCalendarIcon },
    ],
};

function DefaultLayout({ children, path }) {
    const navigate = useNavigate();
    const [isToken, setIsToken] = useState(localStorage.getItem('token'));
    const [roles, setRoles] = useState(localStorage.getItem('roles'));

    // Check token
    useEffect(() => {
        const checkToken = async () => {
            const check = await authApi.checkLoginSessions();
            if (!check.result) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                if (path == '/') {
                    navigate(rule[roles][0]);
                }
            }
        };
        checkToken();
    });

    return (
        <div>
            {!isToken ? (
                <Navigate to="/login" />
            ) : !rule[roles].includes(path) ? (
                <div></div>
            ) : (
                <div>
                    <Header />
                    <Sidebar menuOrigin={menuOrigin[roles]} />
                    <div className={styles.content}>
                        <div className={styles.content_info}>{children}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DefaultLayout;
