import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
            width: 100,
        },
    },
};

export default function CheckMarks({ dataSelect, select, setSelect, titleSelect, width }) {
    // const [select, setSelect] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelect(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <FormControl sx={{ width: width }}>
                <InputLabel id="demo-multiple-checkbox-label">{titleSelect}</InputLabel>
                <Select
                    size="small"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={select}
                    onChange={handleChange}
                    input={<OutlinedInput label={titleSelect} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {dataSelect.map((name) => (
                        <MenuItem key={name} value={name} style={{ padding: '0px' }}>
                            <Checkbox checked={select.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
