import { TextField, Button, Dialog } from '@mui/material';
import SearchData2 from '../SearchData2/SearchData2';
import { useState, useEffect } from 'react';
import customerApi from '~/api/customer/customer';
import MenuItem from '@mui/material/MenuItem';
import { selectStatus } from './data/static.js';

function AddCustomerReport({ show, setShow, dataReport, name, addKHDK }) {
    const [data, setData] = useState();
    const [customerName, setCustomerName] = useState('');
    const [status, setStatus] = useState('Cập nhật thông tin');
    const [note, setNote] = useState('');
    const getData = async (data) => {
        const dataName = await customerApi.getCustomerName();
        setData(dataName);
    };

    useEffect(() => {
        getData();
    }, []);
    const handleClose = () => {
        setShow(false);
    };

    const addData = (name) => {
        console.log(name.label);
        setCustomerName(name.label);
    };
    const addKH = async () => {
        console.log(customerName);
        customerName
            ? addKHDK({
                  customerName,
                  status,
                  note,
              })
            : alert('Vui lòng nhập tên khách hàng');
    };
    return (
        <Dialog
            maxWidth={false}
            open={show}
            onClose={handleClose}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ padding: '20px' }}>
                <h3>{name}</h3>
                <div style={{ width: '500px' }}>
                    <form>
                        <div>
                            <SearchData2 data={data} addData={addData} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <TextField
                                id="outlined-select-currency"
                                fullWidth
                                size="medium"
                                select
                                label="Kế hoạch"
                                defaultValue="Cập nhật thông tin"
                                onChange={(event) => {
                                    setStatus(event.target.value);
                                }}
                            >
                                {selectStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <TextField
                                label="Ghi chú"
                                multiline
                                fullWidth
                                onChange={(event) => {
                                    setNote(event.target.value);
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Button
                                sx={{ padding: '7px 20px' }}
                                type="submit"
                                size="small"
                                variant="contained"
                                component="label"
                                onClick={addKH}
                            >
                                Thêm kế hoạch
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
}

export default AddCustomerReport;
