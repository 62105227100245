import dayjs from 'dayjs';

export const convertMoney = (amount) => {
    try {
        const amountString = amount.toString();

        // Chia chuỗi thành phần nguyên và phần thập phân (nếu có)
        const [integerPart, decimalPart] = amountString.split('.');

        // Thêm dấu chấm sau mỗi 3 số ở phần nguyên
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Kết hợp phần nguyên đã định dạng và phần thập phân (nếu có)
        const formattedAmount = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

        return formattedAmount;
    } catch (error) {
        return 0;
    }
};

export const convertMoney2 = (amount) => {
    try {
        amount = amount.toFixed(0);
        const amountString = amount.toString();

        // Chia chuỗi thành phần nguyên và phần thập phân (nếu có)
        const [integerPart, decimalPart] = amountString.split('.');

        // Thêm dấu chấm sau mỗi 3 số ở phần nguyên
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Kết hợp phần nguyên đã định dạng và phần thập phân (nếu có)
        const formattedAmount = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

        return formattedAmount;
    } catch (error) {
        return 0;
    }
};

export const convertDate = (data) => {
    if (data) {
        return dayjs(data).format('DD-MM-YYYY');
    }
    return data;
};

export const convertNull = (data) => {
    try {
        return data;
    } catch (error) {
        return 0;
    }
};
