import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, NavLink } from 'react-router-dom';
import { TextField, Button, FormControlLabel, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import logo from '~/assets/img/logo.png';
import authApi from '~/api/auth';
import { myIp } from '~/api/auth';
import styles from './Login.module.scss';
import { getMonthBeginEnd } from '~/tool/timeToday';
import ChangePassword from '../ChangePassword/index';

const rule = {
    sale: '/customer',
    admin: '/dashboard',
};

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState(localStorage.getItem('username') ? localStorage.getItem('username') : '');
    const [password, setPassword] = useState('');
    const [notification, setNotification] = useState('');
    const [ip, setIp] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(() => (localStorage.getItem('username') ? true : false));

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const getMyIp = async () => {
            setIp(await myIp());
        };
        getMyIp();
    }, []);
    // Login
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(ip);
        // Xử lý đăng nhập
        const data = {
            username,
            password,
            myIp: ip,
        };
        const checkLogin = await authApi.login(data);
        console.log(checkLogin);

        if (checkLogin.result === true) {
            localStorage.setItem('token', checkLogin.token);
            localStorage.setItem('user', checkLogin.username);
            localStorage.setItem('roles', checkLogin.roles);
            const monthNow = getMonthBeginEnd();
            localStorage.setItem('dateBegin', monthNow.firstDay);
            localStorage.setItem('dateEnd', monthNow.lastDay);
            // Lưu tài khoản
            checked ? localStorage.setItem('username', username) : localStorage.removeItem('username');
            navigate(rule[checkLogin.roles]);
        } else {
            setNotification('Thông tin tài khoản mật khẩu không chính xác!');
        }
    };

    const checkAcc = (event) => {
        setChecked(event.target.checked);
        console.log(event.target.checked);
    };

    return (
        <div className={styles.body}>
            {localStorage.getItem('token') ? (
                <Navigate to={rule[localStorage.getItem('roles')]} />
            ) : (
                <div className={styles.formLogin}>
                    <img src={logo} alt="logo" />
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Tài khoản"
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                onChange={(event) => setPassword(event.target.value)}
                                label="Mật khẩu"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={styles.setting}>
                            <FormControlLabel
                                control={
                                    <Checkbox onChange={checkAcc} checked={checked} value="remember" color="primary" />
                                }
                                label="Remember me"
                            />
                            <div className={styles.changePassword}>
                                <NavLink to="/changepassword">Đổi mật khẩu?</NavLink>
                            </div>
                        </div>

                        <div>
                            <Button type="submit" variant="contained" fullWidth>
                                Đăng nhập
                            </Button>
                        </div>
                        <div className={styles.notification}> {notification}</div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Login;
