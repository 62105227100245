// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckPotential_info__WyJ-R {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  font-size: 10px;
  height: 30px;
  color: white;
  border-radius: 5px;
  background-color: rgb(219, 219, 0);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/pages/compoents/CheckPotential/CheckPotential.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kCAAA;EACA,yHAAA;AACJ","sourcesContent":[".info {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 60px;\n    font-size: 10px;\n    height: 30px;\n    color: #ffff;\n    border-radius: 5px;\n    background-color: rgb(219, 219, 0);\n    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),\n        0px 1px 5px 0px rgba(0, 0, 0, 0.12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `CheckPotential_info__WyJ-R`
};
export default ___CSS_LOADER_EXPORT___;
