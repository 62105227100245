import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function BarsChart({ xData, yData, label, color }) {
    return (
        <div>
            {xData ? (
                <div>
                    {xData.length ? (
                        <BarChart
                            width={yData.length * 60 + 100}
                            height={300}
                            series={[
                                { data: yData, label: label, id: 'uvId', color: color ? color : 'rgb(2, 178, 175)' },
                            ]}
                            xAxis={[{ data: xData, scaleType: 'band' }]}
                        ></BarChart>
                    ) : (
                        <div></div>
                    )}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}
