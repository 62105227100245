import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, NavLink } from 'react-router-dom';
import { TextField, Button, FormControlLabel, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import logo from '~/assets/img/logo.png';
import authApi from '~/api/auth';
import { myIp } from '~/api/auth';
import styles from './Changepassword.module.scss';
import { getMonthBeginEnd } from '~/tool/timeToday';

const rule = {
    sale: '/customer',
    admin: '/dashboard',
};

function ChangePassword() {
    const navigate = useNavigate();
    const [username, setUsername] = useState(localStorage.getItem('username') ? localStorage.getItem('username') : '');
    const [passwordOld, setPasswordOld] = useState('');
    const [passwordNew1, setPasswordNew1] = useState('');
    const [passwordNew2, setPasswordNew2] = useState('');
    const [notification, setNotification] = useState('');
    const [ip, setIp] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(() => (localStorage.getItem('username') ? true : false));

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const getMyIp = async () => {
            setIp(await myIp());
        };
        getMyIp();
    }, []);
    // Đổi mật khẩu
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!(username && passwordOld && passwordNew1 && passwordNew2)) {
            setNotification('Vui lòng nhập đầy đủ thông tin');
        } else if (passwordNew1 != passwordNew2) {
            setNotification('Mật khẩu mới không giống nhau!');
        } else {
            console.log(ip);
            // Xử lý đăng nhập
            const data = {
                username,
                passwordOld,
                passwordNew1,
                myIp: ip,
            };
            const checkLogin = await authApi.changepassword(data);
            console.log(checkLogin);

            if (checkLogin.result === true) {
                setNotification(checkLogin.noti);
            } else {
                setNotification('Thông tin tài khoản mật khẩu không chính xác!');
            }
        }
    };

    return (
        <div className={styles.body}>
            <div className={styles.formLogin}>
                <img src={logo} alt="logo" />
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Tài khoản"
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                        />
                    </div>
                    <div className={styles.passwordDiv}>
                        <TextField
                            fullWidth
                            onChange={(event) => setPasswordOld(event.target.value)}
                            label="Mật khẩu cũ"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={styles.passwordDiv}>
                        <TextField
                            fullWidth
                            onChange={(event) => setPasswordNew1(event.target.value)}
                            label="Mật khẩu mới"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            onChange={(event) => setPasswordNew2(event.target.value)}
                            label="Nhập lại mật khẩu mới"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={styles.login}>
                        <NavLink to="/login">Đăng nhập</NavLink>
                    </div>
                    <div>
                        <Button type="submit" variant="contained" fullWidth>
                            Thay đổi mật khẩu
                        </Button>
                    </div>
                    <div className={styles.notification}> {notification}</div>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;
