import style from './ViewInfo.module.scss';
import { convertMoney } from '~/tool/convert';

function ViewInfo({ pt, value, content, color }) {
    return (
        <div className={style.view}>
            <div className={style.icon} style={{ backgroundColor: color }}>
                {pt}
            </div>
            <div className={style.topic}>
                <div className={style.value}> {convertMoney(value)} </div>
                <div className={style.content}> {content} </div>
            </div>
        </div>
    );
}

export default ViewInfo;
