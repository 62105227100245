import axiosClinet from '~/api/axiosClient';
import axios from 'axios';

const authApi = {
    login(data) {
        const url = '/auth/login';
        return axiosClinet.post(url, { data });
    },
    changepassword(data) {
        const url = '/auth/changepassword';
        return axiosClinet.post(url, { data });
    },
    checkLoginSessions(data) {
        data = {
            token: localStorage.getItem('token'),
        };
        const url = '/auth/loginsesions';
        return axiosClinet.post(url, { data });
    },
    pageInfo(data) {
        data = {
            token: localStorage.getItem('token'),
        };
        console.log(data);
        const url = '/auth/pageinfo';
        return axiosClinet.post(url, { data });
    },
};

export const myIp = async () => {
    try {
        const res = await axios.get('https://api.ipify.org?format=json');
        return res.data.ip;
    } catch (error) {
        console.log(error);
    }
};

export default authApi;
