// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Statistical_setting__DnNxi {
  display: flex;
  justify-content: space-between;
}

.Statistical_add__y6W38 {
  display: flex;
}

.Statistical_add__y6W38 > div {
  padding: 0px 5px;
}

.Statistical_check__TKgii {
  display: flex;
  align-items: center;
}

.Statistical_check__TKgii > div {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/WeekAdmin/Statistical.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".setting {\n    display: flex;\n    justify-content: space-between;\n}\n\n.add {\n    display: flex;\n}\n\n.add > div {\n    padding: 0px 5px;\n}\n\n.check {\n    display: flex;\n    align-items: center;\n}\n\n.check > div {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": `Statistical_setting__DnNxi`,
	"add": `Statistical_add__y6W38`,
	"check": `Statistical_check__TKgii`
};
export default ___CSS_LOADER_EXPORT___;
