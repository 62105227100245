import packApi from '~/api/pack/pack';

const typeSelect = [
    {
        value: 'Khách lẻ',
        label: 'Khách lẻ',
    },
    {
        value: 'Khách đoàn',
        label: 'Khách đoàn',
    },
];

const statusSelect = [
    {
        value: 'Đã có lịch hẹn',
        label: 'Đã có lịch hẹn',
    },
    {
        value: 'Đã huỷ',
        label: 'Đã huỷ',
    },
    {
        value: 'Đã báo giá',
        label: 'Đã báo giá',
    },
    {
        value: 'Đã chốt lịch khám',
        label: 'Đã chốt lịch khám',
    },
    {
        value: 'Đã gửi hợp đồng',
        label: 'Đã gửi hợp đồng',
    },
    {
        value: 'Đã cọc tiền',
        label: 'Đã cọc tiền',
    },
    {
        value: 'Đang khám',
        label: 'Đang khám',
    },
    {
        value: 'Trả hồ sơ',
        label: 'Trả hồ sơ',
    },
    {
        value: 'Hoàn thiện hồ sơ quyết toán',
        label: 'Hoàn thiện hồ sơ quyết toán',
    },
    {
        value: 'Hoàn thiện nốt giá trị hợp đồng',
        label: 'Hoàn thiện nốt giá trị hợp đồng',
    },
];

const potentialSelect = [
    {
        value: 'Done',
        label: 'Done',
    },
    {
        value: 'High',
        label: 'High',
    },
    {
        value: 'Medium',
        label: 'Medium',
    },
    {
        value: 'Low',
        label: 'Low',
    },
];

export const infoCustomer = async () => {
    const packSelect = await packApi.getPack();
    console.log(packSelect);
    return await [
        { name: 'name', label: 'Tên khách hàng:', type: 'text' },
        { name: 'contact', label: 'Liên hệ:', type: 'text' },
        { name: 'note', label: 'Ghi chú:', type: 'text' },
        { name: 'source', label: 'Nguồn', type: 'text' },
        { name: 'type', label: 'Loại', type: 'select', select: typeSelect, defaultSelect: typeSelect[0].value },
        {
            name: 'status',
            label: 'Trạng thái',
            type: 'select',
            select: statusSelect,
            defaultSelect: statusSelect[0].value,
        },
        {
            name: 'potential',
            label: 'Tiềm năng:',
            type: 'select',
            select: potentialSelect,
            defaultSelect: potentialSelect[2].value,
        },
        {
            name: 'package',
            label: 'Đăng kí gói(Mã gói):',
            type: 'select',
            select: await packSelect,
            defaultSelect: await packSelect[0].value,
        },
        { name: 'date_ex', label: 'Ngày khám:', type: 'date' },
        { name: 'date_pay', label: 'Ngày thanh toán:', type: 'date' },
        { name: 'pay', label: 'Thanh toán', type: 'number' },
        { name: 'action', label: 'Hành động', type: 'text' },
    ];
};

export const convertDataChart = (data, x, y, hs) => {
    if (typeof hs == 'undefined') {
        hs = 1;
    }
    // console.log(hs);
    var xData = [];
    var yData = [];
    console.log(data);
    for (const item of data) {
        xData.push(item[x]);
        yData.push(Math.floor(parseFloat(item[y]) / hs)); // Chuyển đổi sang số nếu cần thiết
    }
    return {
        xData,
        yData,
    };
};
