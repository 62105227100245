import * as yup from 'yup';

export const exportForm = [
    {
        name: '',
        contact: '',
        source: '',
        package: '',
        pay: '',
        potential: '',
        date_ex: '',
        date_pay: '',
        action: '',
    },
    // ... more data
];

export const monthSelect = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const yearSelect = ['2023', '2024'];

export const titleCustomerExcel = [
    'name',
    'contact',
    'source',
    'type',
    'potential',
    'package',
    'date_ex',
    'date_pay',
    'pay',
    'note',
];

export const yupCustomer = yup.object().shape({
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
    contact: yup.string().required('Vui lòng nhập dữ liệu!'),
    date_pay: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const yupAction = yup.object().shape({});

export const yupCustomerPay = yup.object().shape({
    pay: yup.string().required('Vui lòng nhập dữ liệu!'),
    date_pay: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const yupCustomerGroupInfo = yup.object().shape({
    customerID: yup.string().required('Vui lòng nhập dữ liệu!'),
});

const statusSelect = [
    {
        value: 'Doanh thu',
        label: 'Doanh thu',
    },
    // {
    //     value: 'Hoàn thành',
    //     label: 'Hoàn thành',
    // },
    {
        value: 'Công nợ',
        label: 'Công nợ',
    },
    {
        value: 'Doanh thu dự kiến',
        label: 'Doanh thu dự kiến',
    },
];

const actionsSelect = [
    {
        value: 'meet',
        label: 'Liên hệ',
    },
];

export const infoCustomerPay = [
    { name: 'date_pay', label: 'Ngày thanh toán:', type: 'date' },
    {
        name: 'status_pay',
        label: 'Loại thanh toán',
        type: 'select',
        select: statusSelect,
        defaultSelect: statusSelect[0].value,
    },
    { name: 'pay', label: 'Thanh toán', type: 'text', format: 'thousand' },
    { name: 'note', label: 'Ghi chú', type: 'note' },
];

export const infoActions = [
    {
        name: 'actions',
        label: 'Loại liên hệ',
        type: 'select',
        select: actionsSelect,
        defaultSelect: actionsSelect[0].value,
    },
    { name: 'note', label: 'Ghi chú', type: 'note' },
    { name: 'date_actions', label: 'Ngày liên hệ', type: 'date' },
];

export const infoCustomerGroupInfo = [
    { name: 'legal', label: 'Thông tin pháp nhân', type: 'text' },
    { name: 'quantity', label: 'Số lượng nhân viên', type: 'text', format: 'thousand' },
    { name: 'budget', label: 'Ngân sách và danh mục', type: 'text' },
    { name: 'date_ex', label: 'Ngày khám:', type: 'date' },
    { name: 'evaluate', label: 'Đánh giá khả thi', type: 'text' },
    { name: 'contract', label: 'Bước chốt hợp đồng', type: 'text' },
];

function countMonthsBetweenDates(dateBegin, dateEnd) {
    // Chuyển đổi các chuỗi ngày thành đối tượng Date
    const begin = new Date(dateBegin);
    const end = new Date(dateEnd);

    // Tính toán số tháng
    const monthsBetween = (end.getFullYear() - begin.getFullYear()) * 12 + (end.getMonth() - begin.getMonth());

    return monthsBetween + 1;
}

export const checkKPI = (value, kpi) => {
    if (localStorage.getItem('dateBegin') && localStorage.getItem('dateEnd')) {
        kpi = countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')) * kpi;
    }
    return Math.floor((value * 100) / kpi);
};

export const checkKPIColor = (value, kpi) => {
    // console.log(countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')));
    if (localStorage.getItem('dateBegin') && localStorage.getItem('dateEnd')) {
        kpi = countMonthsBetweenDates(localStorage.getItem('dateBegin'), localStorage.getItem('dateEnd')) * kpi;
    }
    const colorCheck = Math.floor((value * 100) / kpi);
    if (colorCheck < 50) {
        return 'red';
    }
    if (colorCheck < 80) {
        return '#f0ad4e';
    }
    return '#337ab7';
};
