import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button, Dialog } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            valueIsNumericString
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

// defaultValues: Giá trị khởi tạo
// formDataOrigin: trường biến
// condition: Yup trường điều kiện của biến
// title: Tên form
// showForm: trạng thái hiển thị form
// setShowForm: Hàm trạng thái form
// loadData: Load lại data

function TodoForm({ defaultValues, formDataOrigin, condition, title, confirmForm, show, setShow }) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(condition),
        defaultValues,
    });

    // Tắt hiện thị
    const handleClose = () => {
        setShow(false);
    };

    // Giao diện
    return (
        <Dialog open={show} onClose={handleClose} keepMounted aria-describedby="alert-dialog-slide-description">
            <div style={{ padding: '20px' }}>
                <form onSubmit={handleSubmit(confirmForm)}>
                    <h3 style={{ marginBottom: '0px' }}>{title}</h3>
                    <Controller
                        name="id"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                size="medium"
                                sx={{ display: 'none' }}
                                fullWidth
                                label="ID"
                                variant="outlined"
                                error={!!errors['id']}
                                helperText={errors['id']?.message}
                                {...field}
                            />
                        )}
                    />

                    {formDataOrigin.map((value, index) => {
                        if (value.type === 'select') {
                            return (
                                <Controller
                                    key={index}
                                    name={value.name}
                                    control={control}
                                    defaultValue={value.defaultSelect}
                                    render={({ field }) => (
                                        <TextField
                                            size="medium"
                                            name={value.name}
                                            sx={{ marginTop: '20px' }}
                                            fullWidth
                                            label={value.label}
                                            select
                                            variant="outlined"
                                            format={'DD/MM/YYYY'}
                                            error={!!errors[value.name]}
                                            helperText={errors[value.name]?.message}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...field}
                                        >
                                            {value.select.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            );
                        } else if (value.type === 'date') {
                            return (
                                <Controller
                                    key={index}
                                    name={value.name}
                                    control={control}
                                    defaultValue={value.defaultSelect ? dayjs(value.defaultSelect) : null}
                                    render={({ field }) => {
                                        console.log(field);
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateField', 'DateField']}>
                                                    <DatePicker
                                                        sx={{ padding: '0px' }}
                                                        label={value.label}
                                                        slotProps={{
                                                            textField: {
                                                                size: 'medium',
                                                                fullWidth: '100%',
                                                                style: {
                                                                    display: 'block',
                                                                    marginTop: '12px',
                                                                },
                                                            },
                                                        }}
                                                        format="DD-MM-YYYY"
                                                        value={field.value ? dayjs(field.value) : null}
                                                        onChange={(newDate) => {
                                                            const formattedDate = dayjs(newDate).format('YYYY-MM-DD'); // hoặc định dạng khác nếu cần
                                                            field.onChange(formattedDate);
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        );
                                    }}
                                />
                            );
                        } else {
                            return (
                                <Controller
                                    key={index}
                                    name={value.name}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            size="medium"
                                            sx={{ marginTop: '20px' }}
                                            fullWidth
                                            type={value.type}
                                            InputProps={
                                                value.format == 'thousand'
                                                    ? { inputComponent: NumericFormatCustom }
                                                    : {}
                                            }
                                            label={value.label}
                                            variant="outlined"
                                            error={!!errors[value.name]}
                                            helperText={errors[value.name]?.message}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            );
                        }
                    })}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="primary"
                        sx={{ padding: '10px 0px', marginTop: '10px' }}
                    >
                        Xác nhận
                    </Button>
                </form>
            </div>
        </Dialog>
    );
}

export default TodoForm;
