import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

// const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const yData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
// const xLabels = ['Page A', 'Page B', 'Page C', 'Page D', 'Page E', 'Page F', 'Page G'];

export default function BarsChart2({ columnName1, columnName2, columnName3, uData, yData, xLabels, zData }) {
    return (
        <div>
            {xLabels ? (
                <div>
                    {xLabels.length ? (
                        <BarChart
                            width={xLabels.length * 100 + 100}
                            height={300}
                            series={[
                                { data: yData, label: columnName1, id: columnName1 },
                                { data: uData, label: columnName2, id: columnName2 },
                                { data: zData, label: columnName3, id: columnName3 },
                            ]}
                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                        />
                    ) : (
                        <div></div>
                    )}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}
