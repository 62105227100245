// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewInfo_view__FUtvD {
  width: 200px;
  height: 55px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0.125rem rgba(10, 10, 10, 0.0392156863);
  display: flex;
  cursor: pointer;
}

.ViewInfo_icon__drXjt {
  background-color: rgb(38, 191, 148);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  height: 35px;
  border-radius: 5px;
  font-size: 9px;
  color: #fff;
}

.ViewInfo_topic__ppMtJ {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  min-width: 35px;
  height: 35px;
  border-radius: 5px;
  font-size: 9px;
}

.ViewInfo_content__LM3Rd {
  color: #8c9097;
  font-size: 11px;
  font-weight: 600;
}

.ViewInfo_value__Wm4B2 {
  color: #28529a;
  font-size: 16px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/compoents/ViewInfo/ViewInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,qDAAA;EACA,aAAA;EACA,eAAA;AACJ;;AAEA;EACI,mCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".view {\n    width: 200px;\n    height: 55px;\n    border-radius: 5px;\n    padding: 10px;\n    background-color: #fff;\n    box-shadow: 0 0.125rem #0a0a0a0a;\n    display: flex;\n    cursor: pointer;\n}\n\n.icon {\n    background-color: rgb(38, 191, 148);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 35px;\n    height: 35px;\n    border-radius: 5px;\n    font-size: 9px;\n    color: #fff;\n}\n\n.topic {\n    margin-left: 15px;\n    display: flex;\n    flex-direction: column;\n    min-width: 35px;\n    height: 35px;\n    border-radius: 5px;\n    font-size: 9px;\n}\n\n.content {\n    color: #8c9097;\n    font-size: 11px;\n    font-weight: 600;\n}\n\n.value {\n    color: #28529a;\n    font-size: 16px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `ViewInfo_view__FUtvD`,
	"icon": `ViewInfo_icon__drXjt`,
	"topic": `ViewInfo_topic__ppMtJ`,
	"content": `ViewInfo_content__LM3Rd`,
	"value": `ViewInfo_value__Wm4B2`
};
export default ___CSS_LOADER_EXPORT___;
