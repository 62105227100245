import * as XLSX from 'xlsx';
import React, { useState } from 'react';
import { Button, Dialog } from '@mui/material';
import { CreateNewFolder, ArrowCircleUp } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Swal from 'sweetalert2';

// add: Hàm add
// dataTitleExcel: tên cột excel
// loadData: Load lại trang khi import

function ImportExcel({ add, dataTitleExcel, loadData }) {
    // console.log('check Loop');
    // data: Dữ liệu impot từ excel
    // noti: Thống báo kết quả import
    const [data, setData] = useState([]);
    const [notiExcel, setNotiExcel] = useState([]);
    const [showTable, setShowTable] = useState(false);

    // load file xlxs
    const onFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, {
                    type: 'binary',
                    // cellDates: true,
                    dateNF: 'YYY/MM/DD',
                });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                console.log(worksheet);
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                    raw: false,
                });
                setData(jsonData);
            };
            reader.readAsBinaryString(file);
            setShowTable(true);
        }
    };

    // click import xlxs
    const importExcel = async () => {
        const { isConfirmed } = await Swal.fire({
            title: 'Import',
            text: 'Bạn có muốn tải dữ liệu này lên?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ok',
        });

        console.log(isConfirmed);
        if (isConfirmed) {
            const dataLeng = data.length;
            console.log('Impoort Exx: ' + dataLeng);
            if (dataLeng) {
                var index = 0;
                var loadUp = [
                    {
                        service: `Update: ${index}/${dataLeng}`,
                        color: 'blue',
                    },
                ];
                var erroNoti = [];
                const addServiceTime = setInterval(async () => {
                    // Add dịch vụ
                    const check = await add(data[index]);
                    console.log(check);
                    if (!check.result) {
                        erroNoti = [
                            ...erroNoti,
                            {
                                service: `${JSON.stringify(data[index])} - erro`,
                                color: 'red',
                            },
                        ];
                    }
                    loadUp = [
                        {
                            service: `Loadding: ${index + 1}/${dataLeng}`,
                            color: 'blue',
                        },
                    ];

                    // Dừng vòng lặp
                    if (index + 1 === dataLeng) {
                        console.log('index: ' + index);
                        loadData();
                        loadUp = [
                            {
                                service: `Loading: ${index + 1}/${dataLeng}`,
                                color: 'blue',
                            },
                        ];
                        clearInterval(addServiceTime);
                    }
                    setNotiExcel([...loadUp, ...erroNoti]);
                    index++;
                }, 1000);
            }
        }
    };
    // Giao diện
    const handleClose = () => {
        setShowTable(false);
        setData([]);
    };
    return (
        <div>
            <div>
                <Button variant="contained" component="label" startIcon={<CreateNewFolder />}>
                    Upload .xlxs
                    <input type="file" hidden onChange={onFileChange} />
                </Button>
                {/* <Input type="file" onChange={onFileChange} /> */}
            </div>
            <Dialog
                open={showTable}
                onClose={handleClose}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                {data.length ? (
                    <div>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={importExcel}
                            startIcon={<ArrowCircleUp />}
                        >
                            Import Excel
                        </Button>
                    </div>
                ) : (
                    ''
                )}
                <div>
                    {notiExcel.map((value, index) => (
                        <p key={index} style={{ color: value.color }}>
                            {value.service}
                        </p>
                    ))}
                </div>

                <TableContainer component={Paper}>
                    <Table>
                        {data.length ? (
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stt</TableCell>
                                    {dataTitleExcel.map((title, index) => (
                                        <TableCell key={index}>{title}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                        ) : (
                            ''
                        )}
                        <TableBody>
                            {data.map((value, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        {dataTitleExcel.map((title, index) => (
                                            <TableCell key={index}>{value[title]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </div>
    );
}

export default ImportExcel;
