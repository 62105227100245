// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_body__MAwMd {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.Login_formLogin__L1Jrq {
  width: 500px;
  padding: 50px;
}

.Login_notification__Zk6kJ {
  margin-top: 10px;
  color: red;
}

.Login_setting__fCl7\\+ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Login_changePassword__3eUFw {
  cursor: pointer;
  direction: none;
}

.Login_changePassword__3eUFw > a {
  text-decoration: none;
  color: #1976d2;
}`, "",{"version":3,"sources":["webpack://./src/Auth/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,eAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;AACJ","sourcesContent":[".body {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.formLogin {\n    width: 500px;\n    padding: 50px;\n}\n\n.notification {\n    margin-top: 10px;\n    color: red;\n}\n\n.setting {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.changePassword {\n    cursor: pointer;\n    direction: none;\n}\n\n.changePassword > a {\n    text-decoration: none;\n    color: #1976d2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Login_body__MAwMd`,
	"formLogin": `Login_formLogin__L1Jrq`,
	"notification": `Login_notification__Zk6kJ`,
	"setting": `Login_setting__fCl7+`,
	"changePassword": `Login_changePassword__3eUFw`
};
export default ___CSS_LOADER_EXPORT___;
