import * as React from 'react';
import customerApi from '~/api/customer/customer';
import { useState, useEffect } from 'react';

function Home() {
    const [dataReport, setDataReport] = useState([]);
    const getReport = async () => {
        console.log('lol');
        try {
            const dataTableNew = await customerApi.getReport();
            console.log(dataTableNew);
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            console.log(dataStt);
            setDataReport(dataStt);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getReport();
    }, []);
    return (
        <div>
            <div>lol</div>
        </div>
    );
}

export default Home;
