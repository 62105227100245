import axiosClinet from '~/api/axiosClient';

const customerApi = {
    addCustomer(data) {
        const url = '/customer/add';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    addActions(data) {
        const url = '/customer/addactions';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    addMonth(data) {
        const url = '/customer/addmonth';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    editMonth(data) {
        const url = '/customer/editmonth';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    saveReport(data) {
        const url = '/customer/savereport';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    // addCustomerKHDK

    addCustomerKHDK(data) {
        const url = '/customer/addcustomerkhdk';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    editReport(data) {
        const url = '/customer/editreport';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    payCustomer(data) {
        const url = '/customer/paycustomer';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    groupInfoCustomer(data) {
        const url = '/customer/groupinfocustomer';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    groupInfoCustomerEdit(data) {
        const url = '/customer/groupinfocustomeredit';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    editCustomer(data) {
        const url = '/customer/edit';
        let token = localStorage.getItem('token');
        console.log(data);
        return axiosClinet.post(url, { ...data, token });
    },

    removeCustomer(data) {
        const url = '/customer/remove';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    removeCustomerActions(data) {
        const url = '/customer/removeactions';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    removeReport(data) {
        const url = '/customer/removereport';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    removeCustomerKHDK(data) {
        const url = '/customer/removecustomerkhdk';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    removeCustomerPay(data) {
        const url = '/customer/removepay';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },

    getCustomer(data) {
        const url = '/customer/getcustomer';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getCustomerName(data) {
        const url = '/customer/getcustomername';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getGroup(data) {
        const url = '/customer/getgroup';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getReportCustomerMonth(data) {
        const url = '/customer/getcustomerreport';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getReportMonth(data) {
        const url = '/customer/getreportmonth';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    // getSumMonth

    getSumMonth(data) {
        const url = '/customer/getsummonth';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getCheckCustomer(data) {
        const url = '/customer/getcheckcustomer';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getReport(data) {
        console.log(data);
        const url = '/customer/getreport';
        let token = localStorage.getItem('token');
        const params = { token };
        console.log(params);
        return axiosClinet.get(url, { params });
    },

    getReportDs(data) {
        const url = '/customer/getreportds';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getReportDk(data) {
        const url = '/customer/getreportdk';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getPackageFilter(data) {
        const url = '/customer/getPackageFilter';
        let token = localStorage.getItem('token');
        const params = { token };
        return axiosClinet.get(url, { params });
    },

    getSourceFilter(data) {
        const url = '/customer/getSourceFilter';
        let token = localStorage.getItem('token');
        const params = { token };
        return axiosClinet.get(url, { params });
    },

    getCustomerIDInfo(data) {
        const url = '/customer/getcustomeridinfo';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getCustomerSearch(data) {
        const url = '/customer/getcustomersearch';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getCustomerInfo(data) {
        const url = '/customer/getcustomerinfo';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getCustomerGroupInfo(data) {
        const url = '/customer/getcustomergroupinfo';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
};

export default customerApi;
