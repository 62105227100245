export const timeToday = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
};

export const getMonthToday = () => {
    var currentDate = new Date();

    // Lấy tháng từ đối tượng Date
    return Number(currentDate.getMonth() + 1);
};

export const dateToday = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};

export const getMonthBeginEnd = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 2);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    // Định dạng ngày để lấy chuỗi YYYY-MM-DD
    const formatDate = (date) => date.toISOString().split('T')[0];

    return {
        firstDay: formatDate(firstDay),
        lastDay: formatDate(lastDay),
    };
};
