import { useState, useEffect } from 'react';
import style from './Group.module.scss';
import { NavLink } from 'react-router-dom';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import Swal from 'sweetalert2';
import adminApi from '~/api/admin/admin.js';
import customerApi from '~/api/customer/customer';
import { convertMoney, convertDate } from '~/tool/convert';
import { getMonthToday } from '~/tool/timeToday';
import CheckPotential from '~/pages/compoents/CheckPotential/CheckPotential';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ViewCustomrInfo from '../compoents/ViewCustomerInfo';
import TodoForm from '~/compoents/TodoForm';
import { infoGroupMonth, yupInfoGroup, monthsAR } from './data/static';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Button, TextField } from '@mui/material';
import Customer from '../Customer/index';

function Group() {
    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState(getMonthToday());
    const [sumMonth, setSumMonth] = useState([]);
    const [dataProcedure, setDataProcedure] = useState([]);
    const [dataCustomerID, setDataCustomerID] = useState(false);
    const [showCustomerInfoID, setShowCustomerInfoID] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formInfo, setformInfo] = useState({});
    const [defaultForm, setDefaultForm] = useState([]);
    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });

    useEffect(() => {
        getGroup();
    }, []);

    const getGroup = async () => {
        getSumMonth();

        const dataTableNew = await customerApi.getGroup({ year, month });
        console.log(dataTableNew);
        setDataProcedure(dataTableNew);

        // const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        // setDataProcedure(dataStt);
    };
    const getSumMonth = async () => {
        const dataTableNew = await customerApi.getSumMonth({ year });
        setSumMonth(dataTableNew);

        // const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        // setDataProcedure(dataStt);
    };

    const checkYear = (e) => {
        e.preventDefault();
        getGroup();
    };

    const viewCustomrID = async (event) => {
        const customerID = event.target.getAttribute('customerID');
        const dataID = await customerApi.getCustomerIDInfo({ customerID });
        setDataCustomerID(dataID);
        setShowCustomerInfoID(true);
    };

    const addMonth = async (data) => {
        const check = await customerApi.addMonth(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getGroup();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editMonth = async (data) => {
        console.log('Edit month');
        console.log(data);
        const check = await customerApi.editMonth(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getGroup();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editMonthForm = async (event) => {
        const customerID = event.target.getAttribute('customerID');
        const month = Number(event.target.getAttribute('month'));
        const value = event.target.getAttribute('quantity');
        const dateBegin = event.target.getAttribute('dateBegin');
        const dateEnd = event.target.getAttribute('dateEnd');
        const id = event.target.getAttribute('id');

        // customerID, month, value
        setformInfo({
            info: infoGroupMonth,
            condition: yupInfoGroup,
        });
        console.log(value);

        if (value == undefined || value == '') {
            console.log('Add New:');
            setConfirmForm({
                title: 'Tháng: ' + month,
                confirm: addMonth,
            });
        } else {
            setConfirmForm({
                title: 'Tháng:: ' + month,
                confirm: editMonth,
            });
        }
        setDefaultForm({
            quantity: value,
            id: customerID,
            customerID: customerID,
            month,
            year,
            dateBegin,
            dateEnd,
            id,
        });
        setShowForm(true);
    };

    const clickMonth = async (event) => {
        alert(event.target.getAttribute('quantity'));
    };

    return (
        <div>
            <div>
                <form onSubmit={checkYear} className={style.check}>
                    <div style={{ marginRight: '10px' }}>
                        <TextField
                            sx={{ width: '100px' }}
                            margin="normal"
                            size="small"
                            label="Tháng"
                            type="text"
                            id="ds"
                            name="ds"
                            value={month}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => setMonth(event.target.value)}
                        />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <TextField
                            sx={{ width: '100px' }}
                            margin="normal"
                            size="small"
                            label="Năm"
                            type="text"
                            id="ds"
                            name="ds"
                            value={year}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => setYear(event.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <Button
                            size="small"
                            sx={{ padding: '7px 10px' }}
                            type="submit"
                            variant="contained"
                            component="label"
                            startIcon={<ContentPasteSearchIcon />}
                            onClick={checkYear}
                        >
                            Check
                        </Button>
                    </div>
                </form>
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            {dataCustomerID !== false && (
                <ViewCustomrInfo show={showCustomerInfoID} setShow={setShowCustomerInfoID} data={dataCustomerID} />
            )}
            {/* <div>
                <TodoTable rows={dataProcedure} columns={columns} />
            </div> */}

            <div className={style.table_group}>
                <table>
                    <tr>
                        <th className={style.monthTitle}>Đoàn</th>
                        <th>Pic</th>
                        <th>Số lượng</th>
                        {monthsAR.map((month, index) => (
                            <th key={index} className={style.monthTitle}>
                                <div className={style.sumKH}>{sumMonth.length ? sumMonth[index].quantity : 0}</div>
                                {month}
                            </th>
                        ))}
                    </tr>
                    {dataProcedure.map((customer) => {
                        let monthInfo = customer.data;
                        return (
                            <tr>
                                <td customerID={customer.customerID} onClick={viewCustomrID}>
                                    {customer.name}
                                </td>
                                <td>{customer.pic}</td>
                                <td>{customer.quantity}</td>
                                {monthInfo.map((data) => (
                                    <td
                                        month={data.month_group}
                                        customerID={data.customerID}
                                        onDoubleClick={editMonthForm}
                                    >
                                        {data.dataMonth.map((data) => (
                                            <div
                                                className={data.quantity ? style.monthQuantity : ''}
                                                customerID={data.customerID}
                                                dateBegin={data.dateBegin}
                                                dateEnd={data.dateEnd}
                                                quantity={data.quantity}
                                                month={data.month_group}
                                                id={data.id}
                                                // onDoubleClick={editMonthForm}
                                                onDoubleClick={editMonthForm}
                                            >
                                                {data.quantity != 0 && 'SL: ' + data.quantity} &nbsp;&nbsp; Từ{' '}
                                                {data.dateBegin} đến {data.dateEnd}
                                            </div>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
}

export default Group;
