import axiosClinet from '../axiosClient';
export const procedureApi = {
    addProcedure(data) {
        const url = '/procedure/addprocedure';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    editProcedure(data) {
        const url = '/procedure/editprocedure';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    removeProcedure(data) {
        const url = '/procedure/removeprocedure';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    getProcedure() {
        console.log('Check');
        const url = '/procedure/getprocedure';
        let token = localStorage.getItem('token');
        const params = { token };

        return axiosClinet.get(url, { params });
    },
};
