import axiosClinet from '~/api/axiosClient';

const adminApi = {
    getCustomer(data) {
        const url = '/admin/getcustomer';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getCustomerAll(data) {
        const url = '/admin/getcustomerall';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getGroup(data) {
        const url = '/admin/getgroup';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getCheckCustomer(data) {
        const url = '/admin/getcheckcustomer';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getWarningPay(data) {
        const url = '/admin/getwarningpay';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },

    getDataUser(data) {
        const url = '/admin/getdatauser';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getPackInfo(data) {
        const url = '/admin/getpackinfo';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getCustomerChart(data) {
        const url = '/admin/getcustomerchart';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getCustomerInfo(data) {
        const url = '/admin/getcustomerinfo';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getReport(data) {
        const url = '/admin/getreport';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getReportDs(data) {
        const url = '/admin/getreportds';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getReportDk(data) {
        const url = '/admin/getreportdk';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    setApproveReport(data) {
        console.log(data);
        const url = '/admin/setapprovereport';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    getReportMonth(data) {
        const url = '/admin/getreportmonth';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
    getReportCustomerMonth(data) {
        const url = '/admin/getcustomerreport';
        let token = localStorage.getItem('token');
        const params = { ...data, token };
        return axiosClinet.get(url, { params });
    },
};

export default adminApi;
