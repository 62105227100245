import axiosClinet from '../axiosClient';
export const paperApi = {
    addPaper(data) {
        const url = '/paper/addpaper';
        let token = localStorage.getItem('token');
        // console.log('check_____________');
        return axiosClinet.post(url, { ...data, token });
    },
    editPaper(data) {
        const url = '/paper/editpaper';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    removePaper(data) {
        const url = '/paper/removepaper';
        let token = localStorage.getItem('token');
        return axiosClinet.post(url, { ...data, token });
    },
    getPaper() {
        console.log('Check');
        const url = '/paper/getpaper';
        let token = localStorage.getItem('token');
        const params = { token };

        return axiosClinet.get(url, { params });
    },
};
