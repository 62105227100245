import { useState, useEffect } from 'react';
import style from './Dasboard.module.scss';
import { Button, TextField } from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ViewInfo from '~/compoents/ViewInfo/ViewInfo';
import { checkKPI, checkKPIColor, chartSelect, charTypeSelect } from './data/static.js';
import adminApi from '~/api/admin/admin.js';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import BoyIcon from '@mui/icons-material/Boy';
import BarsChart from '~/compoents/BarsChart/BarsChart';
import { convertDataChart } from './data/dataApi.js';
import PieChartNew from '~/compoents/PieChartNew/PieChartNew';
import { getMonthBeginEnd } from '~/tool/timeToday';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckMarks from '~/compoents/CheckMarks';
import TodoTable from '~/compoents/TodoTable/TodoTable';
import { convertMoney } from '~/tool/convert';
import Typography from '@mui/material/Typography';
import BarsChart2 from '~/compoents/BarsChart2/BarsChart2';
import TextDate from '~/compoents/TextDate/TextDate';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CheckCustomerAdmin from '~/compoents/CheckCustomerAdmin/CheckCustomerAdmin';

function Dashboard() {
    const [dateBegin, setDateBegin] = useState(localStorage.getItem('dateBegin'));
    const [dateEnd, setDateEnd] = useState(localStorage.getItem('dateEnd'));
    const [dataChartPay, setDataChartPay] = useState({ xData: [], yData: [] });
    const [dataChartPayDebt, setDataChartPayDebt] = useState({ xData: [], yData: [] });
    const [dataChartPayGroup, setDataChartPayGroup] = useState({ xData: [], yData: [] });
    const [dataChartPotential, setDataChartPotential] = useState({ xData: [], yData: [] });
    const [dataChartPotentialGroup, setDataChartPotentialGroup] = useState({ xData: [], yData: [] });
    const [dataChartPack, setDataChartPack] = useState({ xData: [], yData: [] });
    const [dataChartSale, setDataChartSale] = useState({ xData: [], yData: [] });
    const [checkView, setcheckView] = useState(false);
    const [dataCustomerCheck, setdataCustomerCheck] = useState([]);

    const [customerInfo, setCustomerInfo] = useState({});
    const [customerInfoChartPay, setCustomerInfoChartPay] = useState([]);
    const [customerTable, setCustomerTable] = useState([]);
    const [packInfoTable, setPackInfoTable] = useState([]);
    const [dataChartSelect, setDataChartSelect] = useState(
        localStorage.getItem('chartSelect')
            ? localStorage.getItem('chartSelect').split(',')
            : [
                  'Doanh thu hàng tháng',
                  'Tỷ lệ doanh thu khách lẻ và khách đoàn',
                  'Lượng khách theo tiềm năng',
                  'Doanh thu theo các dòng sản phẩm',
                  'Doanh thu nhân viên hàng tháng',
              ],
    );

    const [typeCustomerSelect, setTypeCustomerSelect] = useState(
        localStorage.getItem('typeCustomerSelect') ? localStorage.getItem('typeCustomerSelect').split(',') : ['Tất cả'],
    );

    const getCustomer = async () => {
        const dataTableNew = await adminApi.getCustomer({
            dateBegin: localStorage.getItem('dateBegin'),
            dateEnd: localStorage.getItem('dateEnd'),
        });
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setCustomerTable(dataStt);

        const dataPackTable = await adminApi.getPackInfo({
            dateBegin: localStorage.getItem('dateBegin'),
            dateEnd: localStorage.getItem('dateEnd'),
        });

        const dataPackInfoStt = dataPackTable.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setPackInfoTable(dataPackInfoStt);
        console.log(dataPackTable);

        const dataInfo = await adminApi.getCustomerInfo({
            dateBegin: localStorage.getItem('dateBegin'),
            dateEnd: localStorage.getItem('dateEnd'),
        });
        setDataChartPay(convertDataChart(dataInfo[0].dataCustomerChart, 'month_year', 'tong_doanh_thu', 1000000));
        setDataChartPayDebt(convertDataChart(dataInfo[0].dataCustomerChart, 'month_year', 'cong_no', 1000000));

        setDataChartPayGroup(
            convertDataChart(dataInfo[0].dataCustomerChart, 'month_year', 'tong_doanh_thu_group', 1000000),
        );
        setDataChartPotential(convertDataChart(dataInfo[0].dataPotentialChart, 'potential', 'value'));
        setDataChartPotentialGroup(convertDataChart(dataInfo[0].dataPotentialChart, 'potential', 'value_group'));
        setDataChartPack(convertDataChart(dataInfo[0].dataPackChart, 'package', 'value', 1000000));
        setDataChartSale(convertDataChart(dataInfo[0].dataSaleChart, 'pic', 'value', 1000000));
        setCustomerInfo(dataInfo[0]);
        console.log(Object.keys(customerInfo).length);
    };

    const handleCheck = (event) => {
        event.preventDefault();
        localStorage.setItem('dateBegin', dateBegin);
        localStorage.setItem('dateEnd', dateEnd);
        getCustomer();
    };

    const checkCustomerds = async (typePay) => {
        // alert(typePay);
        const dataTableNew = await adminApi.getCheckCustomer({ dt: typePay, dateBegin, dateEnd });
        if (dataTableNew.length) {
            const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
            setdataCustomerCheck(dataStt);
            setcheckView(true);
        } else {
            setdataCustomerCheck([]);
            setcheckView(true);
        }
    };

    const handleCheckMonthNow = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin(monthNow.firstDay);
        setDateEnd(monthNow.lastDay);
        localStorage.setItem('dateBegin', monthNow.firstDay);
        localStorage.setItem('dateEnd', monthNow.lastDay);
        getCustomer();
    };

    useEffect(() => {
        localStorage.setItem('chartSelect', dataChartSelect);
        localStorage.setItem('typeCustomerSelect', typeCustomerSelect);
    }, [dataChartSelect, typeCustomerSelect]);

    useEffect(() => {
        getCustomer();
    }, []);

    const columns = [
        { field: 'stt', headerName: 'STT', flex: 3 },
        { field: 'pic', headerName: 'Nhân viên', flex: 5 },
        { field: 'pay', headerName: 'Doanh thu', flex: 5, renderCell: (params) => convertMoney(params.row.pay) },
        { field: 'call', headerName: 'SL gọi khách đoàn', flex: 5 },
        { field: 'meet', headerName: 'SL gặp khách đoàn', flex: 5 },
    ];

    const columnsPack = [
        { field: 'stt', headerName: 'STT', flex: 3 },
        { field: 'package', headerName: 'Gói', flex: 5 },
        { field: 'Done', headerName: 'Done', flex: 5 },
        { field: 'High', headerName: 'High', flex: 5 },
        { field: 'Medium', headerName: 'Medium', flex: 5 },
        { field: 'Low', headerName: 'Low', flex: 5 },
    ];
    return (
        <div>
            <div className={style.setting}>
                {checkView && (
                    <CheckCustomerAdmin customerTable={dataCustomerCheck} show={checkView} setShow={setcheckView} />
                )}

                <div>
                    <form onSubmit={handleCheck} className={style.check}>
                        <div>
                            <TextDate label="Từ ngày" value={dateBegin} setValue={setDateBegin}></TextDate>
                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Từ ngày"
                                type="date"
                                id="dateBegin"
                                name="dateBegin"
                                value={dateBegin}
                                onChange={(event) => setDateBegin(event.target.value)}
                            /> */}
                        </div>
                        <div>
                            <TextDate label="Đến ngày" value={dateEnd} setValue={setDateEnd}></TextDate>

                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Đến ngày"
                                type="date"
                                id="dateEnd"
                                name="dateEnd"
                                value={dateEnd}
                                onChange={(event) => setDateEnd(event.target.value)}
                            /> */}
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                sx={{ padding: '7px 20px' }}
                                type="submit"
                                size="small"
                                variant="contained"
                                component="label"
                                startIcon={<ContentPasteSearchIcon />}
                                onClick={handleCheck}
                            >
                                Check
                            </Button>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                size="small"
                                sx={{ padding: '7px 10px' }}
                                type="submit"
                                variant="contained"
                                component="label"
                                startIcon={<AccessTimeFilledIcon />}
                                onClick={handleCheckMonthNow}
                            >
                                Tháng này
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <div className={style.dashboard}>
                <div onDoubleClick={() => checkCustomerds('adminds')}>
                    <ViewInfo
                        value={customerInfo.customerPay + ' ₫'}
                        pt={<MonetizationOnIcon />}
                        color=""
                        content="Doanh thu"
                    />
                </div>
                <div onDoubleClick={() => checkCustomerds('admincn')}>
                    <ViewInfo
                        value={customerInfo.customerPayDebt + ' ₫'}
                        pt={<AccountBalanceWalletIcon />}
                        color=""
                        content="Công nợ"
                    />
                </div>
                <div onDoubleClick={() => checkCustomerds('admindsd')}>
                    <ViewInfo
                        value={customerInfo.customerPayGroup + ' ₫'}
                        pt={<FamilyRestroomOutlinedIcon />}
                        color="rgb(35, 183, 229)"
                        content="Doanh thu Khách đoàn"
                    />
                </div>
                <div onDoubleClick={() => checkCustomerds('admmindsl')}>
                    <ViewInfo
                        value={customerInfo.customerPayRetail + ' ₫'}
                        pt={<BoyIcon />}
                        color="rgb(35, 183, 229)"
                        content="Doanh thu Khách lẻ"
                    />
                </div>
                <div onDoubleClick={() => checkCustomerds('adminkdd')}>
                    <ViewInfo
                        value={customerInfo.customerCountRetail}
                        pt={<FamilyRestroomOutlinedIcon />}
                        color="rgb(245, 184, 73)"
                        content="SL khách đoàn (DONE)"
                    />
                </div>
                <div onDoubleClick={() => checkCustomerds('admminkld')}>
                    <ViewInfo
                        value={customerInfo.customerCountGroup}
                        pt={<BoyIcon />}
                        color="rgb(245, 184, 73)"
                        content="SL Khách lẻ (DONE)"
                    />
                </div>
            </div>
            <div className={style.select}>
                <div className={style.chartSelect}>
                    <CheckMarks
                        dataSelect={charTypeSelect}
                        select={typeCustomerSelect}
                        setSelect={setTypeCustomerSelect}
                        titleSelect="Phân loại"
                        width={100}
                    />
                </div>
                <div className={style.chartSelect}>
                    <CheckMarks
                        dataSelect={chartSelect}
                        select={dataChartSelect}
                        setSelect={setDataChartSelect}
                        titleSelect="Biểu đồ"
                        width={300}
                    />
                </div>
            </div>

            <div className={style.chartInfo}>
                {typeCustomerSelect.includes('Tất cả') && dataChartSelect.includes('Doanh thu hàng tháng') && (
                    <div>
                        <BarsChart2
                            uData={dataChartPayGroup.yData}
                            yData={dataChartPay.yData}
                            zData={dataChartPayDebt.yData}
                            xLabels={dataChartPay.xData}
                            width={500}
                            columnName1="Khách lẻ"
                            columnName2="Khách đoàn"
                            columnName3="Công nợ"
                        />
                        <div className={style.chartTitle}>Biểu đồ Doanh thu thực tế hàng tháng (Đơn vị triệu) </div>
                    </div>
                )}
                {typeCustomerSelect.includes('Khách lẻ') && dataChartSelect.includes('Doanh thu hàng tháng') && (
                    <div>
                        <BarsChart
                            xData={dataChartPay.xData}
                            yData={dataChartPay.yData}
                            label="Doanh thu (triệu)"
                            width={500}
                        />
                        <div className={style.chartTitle}>Biểu đồ Doanh thu thực tế hàng tháng (Khách lẻ)</div>
                    </div>
                )}

                {typeCustomerSelect.includes('Khách đoàn') && dataChartSelect.includes('Doanh thu hàng tháng') && (
                    <div>
                        <BarsChart
                            xData={dataChartPayGroup.xData}
                            yData={dataChartPayGroup.yData}
                            label="Doanh thu (triệu)"
                            width={500}
                            color="#2E96FF"
                        />
                        <div className={style.chartTitle}>Biểu đồ Doanh thu thực tế hàng tháng (Khách Đoàn)</div>
                    </div>
                )}
                {typeCustomerSelect.includes('Tất cả') &&
                    dataChartSelect.includes('Tỷ lệ doanh thu khách lẻ và khách đoàn') && (
                        <div>
                            {Object.keys(customerInfo).length ? (
                                <PieChartNew
                                    data={[
                                        { id: 0, value: Number(customerInfo.customerPayRetail), label: 'DT khám lẻ' },
                                        { id: 1, value: Number(customerInfo.customerPayGroup), label: 'DT Khám đoàn' },
                                    ]}
                                />
                            ) : (
                                <div></div>
                            )}
                            <div className={style.chartTitle}>Biểu đồ Tỷ lệ doanh thu khách lẻ và khách đoàn</div>
                        </div>
                    )}
                {(typeCustomerSelect.includes('Tất cả') || typeCustomerSelect.includes('Khách lẻ')) &&
                    dataChartSelect.includes('Lượng khách theo tiềm năng') && (
                        <div>
                            <BarsChart
                                xData={dataChartPotential.xData}
                                yData={dataChartPotential.yData}
                                label="Lượng khách"
                                width={300}
                            />
                            <div className={style.chartTitle}>Biểu đồ lượng khách theo tiềm năng (Khách lẻ)</div>
                        </div>
                    )}
                {(typeCustomerSelect.includes('Tất cả') || typeCustomerSelect.includes('Khách đoàn')) &&
                    dataChartSelect.includes('Lượng khách theo tiềm năng') && (
                        <div>
                            <BarsChart
                                xData={dataChartPotentialGroup.xData}
                                yData={dataChartPotentialGroup.yData}
                                label="Lượng Đoàn"
                                width={300}
                                color="#2E96FF"
                            />
                            <div className={style.chartTitle}>Biểu đồ lượng đoàn theo tiềm năng (Khách đoàn)</div>
                        </div>
                    )}
                {typeCustomerSelect.includes('Tất cả') &&
                    dataChartSelect.includes('Doanh thu nhân viên hàng tháng') && (
                        <div>
                            <BarsChart
                                xData={dataChartSale.xData}
                                yData={dataChartSale.yData}
                                label="Doanh thu (triệu)"
                                width={700}
                            />
                            <div className={style.chartTitle}>Doanh thu nhân viên thực tế hàng tháng</div>
                        </div>
                    )}
                {typeCustomerSelect.includes('Tất cả') &&
                    dataChartSelect.includes('Doanh thu theo các dòng sản phẩm') && (
                        <div>
                            <BarsChart
                                xData={dataChartPack.xData}
                                yData={dataChartPack.yData}
                                label="Doanh thu (triệu)"
                                width={700}
                            />
                            <div className={style.chartTitle}>Biểu Đồ doanh thu theo các dòng sản phẩm</div>
                        </div>
                    )}
            </div>
        </div>
    );
}

export default Dashboard;
