import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function PieChartNew({ data }) {
    return (
        <div>
            {data.length ? (
                <PieChart
                    series={[
                        {
                            data,
                        },
                    ]}
                    width={350}
                    height={150}
                />
            ) : (
                <div></div>
            )}
        </div>
    );
}
