import { useState, useEffect } from 'react';
import style from './Customer.module.scss';
import { Button, TextField } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import ImportExcel from '~/compoents/ImportExcel';
import ExportExcel from '~/compoents/ExportExcel/ExportExcel';
import TodoForm from '~/compoents/TodoForm';
import customerApi from '~/api/customer/customer';
import packApi from '~/api/pack/pack';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import CheckPotential from '../compoents/CheckPotential/CheckPotential';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ViewInfo from '~/compoents/ViewInfo/ViewInfo';
import DivDblclick from '~/compoents/DivDblclick/DivDblclick';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { dateToday } from '~/tool/timeToday';
import { convertMoney, convertDate } from '~/tool/convert';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { getMonthBeginEnd } from '~/tool/timeToday';
import Search from '~/compoents/Search/Search';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ViewCustomrInfo from '../compoents/ViewCustomerInfo';
import CheckMarksMin from '~/compoents/CheckMarksMin';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TextDate from '~/compoents/TextDate/TextDate';
import dayjs from 'dayjs';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

import {
    yupCustomer,
    yupAction,
    exportForm,
    titleCustomerExcel,
    infoActions,
    infoCustomerPay,
    yupCustomerPay,
    checkKPI,
    checkKPIColor,
    infoCustomerGroupInfo,
    yupCustomerGroupInfo,
} from './data/static.js';
import { infoCustomer, potentialFilter } from './data/dataApi.js';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { set } from 'react-hook-form';

console.log('Customer');

function Customer() {
    const [dateBegin, setDateBegin] = useState(localStorage.getItem('dateBegin'));
    const [customerInfo, setCustomerInfo] = useState([]);
    const [dateEnd, setDateEnd] = useState(localStorage.getItem('dateEnd'));
    const [customerTable, setCustomerTable] = useState([]);
    const [defaultForm, setDefaultForm] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formInfo, setformInfo] = useState({});
    const [packInfo, setpackInfo] = useState([]);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [dataCustomerInfo, setDataCustomerInfo] = useState({});
    const [dataSearch, setDataSearch] = useState('');
    const [showCustomerInfoID, setShowCustomerInfoID] = useState(false);
    const [dataCustomerID, setDataCustomerID] = useState(false);
    const [saleReal, setsaleReal] = useState('');
    const [saleFake, setSaleFake] = useState('');
    const [debt, setDebt] = useState('');
    // const [dataCustomerFilter, setDataCustomerFilter] = useState([]);
    const [potentialSelect, setPotentialSelect] = useState(
        localStorage.getItem('potentialSelect') ? localStorage.getItem('potentialSelect').split(',') : ['All'],
    );
    const [dataPackageFilter, setDataPackageFilter] = useState([]);
    const [packagelSelect, setPackagelSelect] = useState(
        localStorage.getItem('packagelSelect') ? localStorage.getItem('packagelSelect').split(',') : ['All'],
    );

    const [dataSourceFilter, setDataSourceFilter] = useState([]);

    const [sourceSelect, setSourceSelect] = useState(
        localStorage.getItem('sourceSelect') ? localStorage.getItem('sourceSelect').split(',') : ['All'],
    );

    const getFilterCall = async () => {
        const pack = await customerApi.getPackageFilter();
        setDataPackageFilter(pack);
        const source = await customerApi.getSourceFilter();
        setDataSourceFilter(source);
    };

    useEffect(() => {
        localStorage.setItem('potentialSelect', potentialSelect);
        localStorage.setItem('packagelSelect', packagelSelect);
        localStorage.setItem('sourceSelect', sourceSelect);
        if (dataPackageFilter.length && dataSourceFilter.length) {
            getCustomer();
        }
    }, [potentialSelect, packagelSelect, sourceSelect, dataSourceFilter, dataPackageFilter]);

    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });

    const getFilter = (data) => {
        if (!potentialSelect.includes('All')) {
            potentialFilter.map((value) => {
                if (!potentialSelect.includes(value)) {
                    data = data.filter((item) => !item.potential.toLowerCase().includes(value.toLowerCase()));
                }
            });
        }
        if (!packagelSelect.includes('All')) {
            dataPackageFilter.map((value) => {
                if (!packagelSelect.includes(value)) {
                    data = data.filter((item) => !item.package.toLowerCase().includes(value.toLowerCase()));
                }
            });
        }
        if (!sourceSelect.includes('All')) {
            dataSourceFilter.map((value) => {
                if (!sourceSelect.includes(value)) {
                    data = data.filter((item) => !item.source.toLowerCase().includes(value.toLowerCase()));
                }
            });
        }
        let real = 0;
        let fake = 0;
        let debt = 0;
        data.map((value) => {
            if (value.status_pay == 'Doanh thu') {
                real += value.pay;
            }
            if (value.status_pay == 'Doanh thu' || value.status_pay == 'Doanh thu dự kiến') {
                fake += value.pay;
            }
            if (value.status_pay == 'Công nợ') {
                debt += value.pay;
            }
        });
        setsaleReal(convertMoney(real) + '₫');
        setSaleFake(convertMoney(fake) + '₫');
        setDebt(convertMoney(debt) + '₫');
        return data;
    };

    const getCustomer = async (check) => {
        var dataTableNew;
        try {
            if (!dataSearch || check === 'date') {
                setDataSearch('');
                dataTableNew = await customerApi.getCustomer({
                    dateBegin: localStorage.getItem('dateBegin'),
                    dateEnd: localStorage.getItem('dateEnd'),
                });
            } else {
                dataTableNew = await customerApi.getCustomerSearch({
                    key: dataSearch,
                });
            }

            if (dataTableNew.length) {
                dataTableNew = getFilter(dataTableNew);
                const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
                setCustomerTable(dataStt);
            } else {
                setCustomerTable([]);
                getFilter(dataTableNew);
            }
            const dataInfo = await customerApi.getCustomerInfo({
                dateBegin: localStorage.getItem('dateBegin'),
                dateEnd: localStorage.getItem('dateEnd'),
            });
            setCustomerInfo(dataInfo[0]);
        } catch (error) {
            setCustomerTable([]);
            getFilter(dataTableNew);
        }
    };

    const getPack = async () => {
        const packInfoNow = await packApi.getPack();
        setpackInfo(packInfoNow);
    };

    useEffect(() => {
        getPack();
        getFilterCall();
    }, []);

    const addCustomer = async (data) => {
        const check = await customerApi.addCustomer(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const addCustomerForm = () => {
        setConfirmForm({
            title: 'Thêm thông tin khách hàng',
            confirm: addCustomer,
        });
        setDefaultForm({ date_pay: dateToday() });
        setformInfo({
            info: infoCustomer(packInfo),
            condition: yupCustomer,
        });
        setShowForm(true);
    };

    const addActions = async (data) => {
        const check = await customerApi.addActions(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    // viewAddActions
    const addActionsForm = (customerID, typeCustomer) => {
        setConfirmForm({
            title: 'Thêm liên hệ khách hàng',
            confirm: addActions,
        });
        setDefaultForm({ customerID, typeCustomer, date_actions: dateToday() });
        setformInfo({
            info: infoActions,
            condition: yupAction,
        });
        setShowForm(true);
        // else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Chỉ dành cho khách khám đoàn',
        //     });
        // }
    };

    const groupInfoCustomer = async (data) => {
        console.log('Check2');
        const check = await customerApi.groupInfoCustomer(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const groupInfoCustomerEdit = async (data) => {
        console.log('Edit Group Info');
        const check = await customerApi.groupInfoCustomerEdit(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const groupInfoCustomerForm = async (data) => {
        console.log(data);
        const infoCustomerGroupData = await customerApi.getCustomerGroupInfo({
            customerID: data.customerID,
        });
        if (data.type == 'Khách đoàn') {
            if (infoCustomerGroupData.length > 0) {
                setDefaultForm({ ...infoCustomerGroupData[0], customerID: data.customerID });
                setConfirmForm({
                    title: `Thông tin thay: ${data.name}`,
                    confirm: groupInfoCustomerEdit,
                });
            } else {
                setDefaultForm({ customerID: data.customerID });
                setConfirmForm({
                    title: `Thông tin: ${data.name}`,
                    confirm: groupInfoCustomer,
                });
            }
            setformInfo({
                info: infoCustomerGroupInfo,
                condition: yupCustomerGroupInfo,
            });
            setShowForm(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Chỉ có thể thêm thông tin cho khách đoàn!',
            });
        }
    };

    const payCustomerForm = (data) => {
        setConfirmForm({
            title: `KH.${data.name} Thanh toán:`,
            confirm: payCustomer,
        });
        setDefaultForm({ customerID: data.customerID, date_pay: dateToday() });
        setformInfo({
            info: infoCustomerPay,
            condition: yupCustomerPay,
        });
        setShowForm(true);
    };

    const payCustomer = async (data) => {
        console.log(data);
        const check = await customerApi.payCustomer(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editCustomer = async (data) => {
        const check = await customerApi.editCustomer(data);
        if (check.result) {
            setShowForm(false);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getCustomer();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    var editCustomerForm = (data) => {
        setConfirmForm({
            title: 'Chỉnh sủa thông tin khách hàng',
            confirm: editCustomer,
        });
        setDefaultForm(data);
        setformInfo({
            info: infoCustomer(packInfo),
            condition: yupCustomer,
        });
        setShowForm(true);
    };

    var removeCustomer = async (data) => {
        console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Xoá khách hàng?',
            text: data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await customerApi.removeCustomer(data);
            if (resultRemove.result) {
                Swal.fire('Đã xoá thông tin khách hàng Khách hàng:', data.name, 'success');
                getCustomer();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };

    const customerInfoID = (data) => {
        console.log(data);
        setDataCustomerInfo(data);
        setShowCustomerInfo(true);
    };

    const handleCheck = (event) => {
        event.preventDefault();
        localStorage.setItem('dateBegin', dateBegin);
        localStorage.setItem('dateEnd', dateEnd);
        getCustomer('date');
    };

    const handleCheckMonthNow = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin(monthNow.firstDay);
        setDateEnd(monthNow.lastDay);
        localStorage.setItem('dateBegin', monthNow.firstDay);
        localStorage.setItem('dateEnd', monthNow.lastDay);
        getCustomer('date');
    };
    const handleCheckNull = (event) => {
        event.preventDefault();
        const monthNow = getMonthBeginEnd();
        setDateBegin('');
        setDateEnd('');
        localStorage.removeItem('dateBegin');
        localStorage.removeItem('dateEnd');
        getCustomer('date');
    };

    const viewCustomrID = async (data) => {
        const dataID = await customerApi.getCustomerIDInfo({ customerID: data.customerID });
        setDataCustomerID(dataID);
        setShowCustomerInfoID(true);
    };

    const removePay = async (data) => {
        console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Xoá thanh toán này?',
            text: data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await customerApi.removeCustomerPay(data);
            if (resultRemove.result) {
                Swal.fire('Đã xoá thanh toán của khánh hàng:', data.name, 'success');
                getCustomer();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };

    const columns = [
        {
            field: 'customerID',
            headerName: 'Xem',
            maxWidth: 66,
            renderCell: (params) => (
                <div>
                    <WysiwygIcon
                        onClick={() => viewCustomrID(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <AddIcCallIcon
                        onClick={() => addActionsForm(params.row.customerID, params.row.type)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                </div>
            ),
        },
        {
            field: 'stt',
            headerName: '.STT',
            maxWidth: 30,
            renderCell: (params) => (
                <DivDblclick content={params.row.stt} funDbClick={customerInfoID} data={params.row}></DivDblclick>
            ),
        },
        {
            field: 'name',
            headerName: '.Khách hàng',
            minWidth: 250,
            renderCell: (params) => (
                <div className={style.nameInfo}>
                    <DivDblclick content={params.row.name} funDbClick={editCustomerForm} data={params.row} />
                </div>
            ),
        },
        {
            field: 'potential',
            headerName: 'Tiềm năng',
            minWidth: 60,
            renderCell: (params) => <CheckPotential potential={params.row.potential} />,
        },
        { field: 'status', headerName: 'Trạng thái', minWidth: 150 },
        {
            field: 'date_pay',
            headerName: '.Ngày thanh toán',
            minWidth: 150,
            renderCell: (params) => (
                <DivDblclick
                    content={convertDate(params.row.date_pay)}
                    funDbClick={payCustomerForm}
                    data={params.row}
                />
            ),
        },
        {
            field: 'status_pay',
            headerName: 'Số tiền (₫)',
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ width: '100%' }} onDoubleClick={() => removePay(params.row)}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#28529a' }}>
                        {convertMoney(params.row.pay)} ₫
                    </div>
                    <div style={{ fontSize: '10px' }}>{params.row.status_pay}</div>
                </div>
            ),
        },
        {
            field: 'date_ex',
            headerName: 'Ngày khám',
            minWidth: 150,
            renderCell: (params) => <div>{convertDate(params.row.date_ex)}</div>,
        },
        {
            field: 'type',
            headerName: '.Loại',
            minWidth: 150,
            renderCell: (params) => (
                <DivDblclick content={params.row.type} funDbClick={groupInfoCustomerForm} data={params.row} />
            ),
        },
        { field: 'package', headerName: 'Gói', minWidth: 150 },
        // { field: 'expected', headerName: 'Dự kiến (Đã bỏ)', minWidth: 150 },

        { field: 'contact', headerName: 'Liên hệ', minWidth: 150 },
        { field: 'source', headerName: 'Nguồn', minWidth: 150 },
        { field: 'note', headerName: 'Ghi chú', minWidth: 150 },
        {
            field: 'Actions',
            headerName: 'Actions',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <>
                    <LocalAtmIcon
                        onClick={() => payCustomerForm(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <PendingActionsIcon
                        onClick={() => groupInfoCustomerForm(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <EditIcon
                        onClick={() => editCustomerForm(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removePay(params.row)} style={{ cursor: 'pointer' }} />
                </>
            ),
        },
    ];

    const handleSearch = async (e) => {
        e.preventDefault();
        getCustomer();
        // console.log(dataSearch);
    };

    return (
        <div>
            <div>
                {showCustomerInfo && (
                    <customerInfo show={showCustomerInfo} setShow={setShowCustomerInfo} data={dataCustomerInfo} />
                )}
            </div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            {dataCustomerID !== false && (
                <ViewCustomrInfo show={showCustomerInfoID} setShow={setShowCustomerInfoID} data={dataCustomerID} />
            )}
            <div className={style.setting}>
                <div>
                    <form onSubmit={handleCheck} className={style.check}>
                        <div>
                            <TextDate label="Từ ngày" value={dateBegin} setValue={setDateBegin}></TextDate>
                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Từ ngày"
                                type="date"
                                id="dateBegin"
                                name="dateBegin"
                                value={dateBegin}
                                onChange={(event) => setDateBegin(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            /> */}
                        </div>
                        <div>
                            <TextDate label="Đến ngày" value={dateEnd} setValue={setDateEnd}></TextDate>
                            {/* <TextField
                                margin="normal"
                                size="small"
                                label="Đến ngày"
                                type="date"
                                id="dateEnd"
                                name="dateEnd"
                                value={dateEnd}
                                onChange={(event) => setDateEnd(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            /> */}
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                size="small"
                                sx={{ padding: '7px 10px' }}
                                type="submit"
                                variant="contained"
                                component="label"
                                startIcon={<ContentPasteSearchIcon />}
                                onClick={handleCheck}
                            >
                                Check
                            </Button>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                size="small"
                                sx={{ padding: '7px 10px' }}
                                type="submit"
                                variant="contained"
                                component="label"
                                startIcon={<AccessTimeFilledIcon />}
                                onClick={handleCheckMonthNow}
                            >
                                Tháng này
                            </Button>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Button
                                size="small"
                                sx={{ padding: '7px 10px' }}
                                type="submit"
                                variant="contained"
                                component="label"
                                startIcon={<SearchOffIcon />}
                                onClick={handleCheckNull}
                            >
                                NULL
                            </Button>
                        </div>
                    </form>
                </div>
                <div className={style.add}>
                    <div style={{ display: 'none' }}>
                        <ExportExcel data={exportForm} fileName="CustomerImport" sheetName="Sheet 1" />
                    </div>
                    <div style={{ display: 'none' }}>
                        <ImportExcel
                            add={customerApi.addCustomer}
                            dataTitleExcel={titleCustomerExcel}
                            loadData={getCustomer}
                        />
                    </div>

                    <div>
                        <Button
                            sx={{}}
                            variant="contained"
                            onClick={addCustomerForm}
                            component="label"
                            startIcon={<AddBox />}
                        >
                            Thêm KH
                        </Button>
                    </div>
                </div>
            </div>

            <div className={style.dashboard}>
                <ViewInfo
                    value={customerInfo.customerPay + ' ₫'}
                    pt={checkKPI(customerInfo.customerPay, 1000000000) + '%'}
                    color={checkKPIColor(customerInfo.customerPay, 1000000000)}
                    content="Doanh thu thực tế"
                />
                <ViewInfo
                    value={customerInfo.customerMeet}
                    pt={checkKPI(customerInfo.customerMeet, 30) + '%'}
                    color={checkKPIColor(customerInfo.customerMeet, 30)}
                    content="SL Đoàn đã liên hệ"
                />
                <ViewInfo
                    value={customerInfo.customerNin}
                    pt={checkKPI(customerInfo.customerNin, 2) + '%'}
                    color={checkKPIColor(customerInfo.customerNin, 2)}
                    content="Khách khám NIN"
                />
                <ViewInfo
                    value={customerInfo.customerKSK}
                    pt={checkKPI(customerInfo.customerKSK, 2) + '%'}
                    color={checkKPIColor(customerInfo.customerKSK, 2)}
                    content="Khách khám KSK"
                />
                {false && (
                    <ViewInfo
                        value={customerInfo.customerDating}
                        pt={checkKPI(customerInfo.customerDating, 30) + '%'}
                        color={checkKPIColor(customerInfo.customerDating, 30)}
                        content="SL Đoàn đang hẹn"
                    />
                )}
            </div>

            <div className={style.filterAndSearch}>
                <form onSubmit={handleSearch} className={style.search}>
                    <Search setDataSearch={setDataSearch} dataSearch={dataSearch} />
                    <div>
                        <Button
                            sx={{ padding: '7px 20px' }}
                            variant="contained"
                            onClick={handleSearch}
                            component="label"
                        >
                            Tìm kiếm
                        </Button>
                    </div>
                </form>
                <div className={style.filter}>
                    <div>
                        <TextField
                            sx={{ width: '150px' }}
                            margin="normal"
                            size="small"
                            label="Doanh số thực tế"
                            type="text"
                            id="ds"
                            name="ds"
                            value={saleReal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            sx={{ width: '150px' }}
                            margin="normal"
                            size="small"
                            label="Doanh số dự kiến"
                            type="text"
                            id="ds"
                            name="ds"
                            value={saleFake}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        <TextField
                            sx={{ width: '150px' }}
                            margin="normal"
                            size="small"
                            label="Công nợ"
                            type="text"
                            id="ds"
                            name="ds"
                            value={debt}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className={style.filter}>
                        <CheckMarksMin
                            dataSelect={potentialFilter}
                            select={potentialSelect}
                            setSelect={setPotentialSelect}
                            titleSelect="Tiềm năng"
                            width={80}
                        />
                        <CheckMarksMin
                            dataSelect={dataPackageFilter}
                            select={packagelSelect}
                            setSelect={setPackagelSelect}
                            titleSelect="Gói"
                            width={80}
                        />
                        <CheckMarksMin
                            dataSelect={dataSourceFilter}
                            select={sourceSelect}
                            setSelect={setSourceSelect}
                            titleSelect="Nguồn"
                            width={80}
                        />
                    </div>
                </div>
            </div>
            <div>
                <TodoTable rows={customerTable} columns={columns} />
            </div>
        </div>
    );
}

export default Customer;
