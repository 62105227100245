import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

const ExportExcel = ({ data, fileName, sheetName }) => {
    const exportToExcel = () => {
        console.log('Check Export');
        console.log(data);
        console.log(fileName);
        console.log(sheetName);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Chuẩn bị dữ liệu
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };

        // Chuyển đổi thành ArrayBuffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Tạo Blob từ ArrayBuffer
        const blob = new Blob([excelBuffer], { type: fileType });

        // Lưu file
        FileSaver.saveAs(blob, fileName + fileExtension);
    };

    return (
        <Button variant="contained" component="label" startIcon={<GetAppIcon />} onClick={exportToExcel}>
            File mẫu
        </Button>
    );
};

export default ExportExcel;
