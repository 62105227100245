import { useState, useEffect } from 'react';
import style from './Paper.module.scss';
import { Button, TextField } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import TodoForm from '~/compoents/TodoForm';
import { paperApi } from '~/api/paper/paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, NavLink } from 'react-router-dom';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import Swal from 'sweetalert2';
import { yupPaper, samplePaper } from './data/static.js';

function Paper() {
    const [dataPaper, setDataPaper] = useState([]);
    const [defaultForm, setDefaultForm] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formInfo, setformInfo] = useState({});
    const [confirmForm, setConfirmForm] = useState({
        title: '',
        confirm: '',
    });

    useEffect(() => {
        getPaper();
    }, []);

    const getPaper = async () => {
        const dataTableNew = await paperApi.getPaper();
        const dataStt = dataTableNew.map((value, index) => ({ ...value, stt: index + 1, id: index + 1 }));
        setDataPaper(dataStt);
    };
    const addPaper = async (data) => {
        const check = await paperApi.addPaper(data);
        if (check.result) {
            setShowForm(false);
            setDefaultForm([]);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getPaper();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };
    const addPaperForm = () => {
        setConfirmForm({
            title: `Thêm thông tin quy trình`,
            confirm: addPaper,
        });
        setDefaultForm({});
        setformInfo({
            info: samplePaper,
            condition: yupPaper,
        });
        setShowForm(true);
    };

    const editPaper = async (data) => {
        const check = await paperApi.editPaper(data);
        if (check.result) {
            setShowForm(false);
            Swal.fire({
                icon: 'success',
                title: check.noti,
                showConfirmButton: false,
                timer: 1500,
            });
            getPaper();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: check.noti,
            });
        }
    };

    const editPaperForm = (data) => {
        setConfirmForm({
            title: 'Chỉnh sửa thông tin quy trình',
            confirm: editPaper,
        });
        setDefaultForm(data);
        setformInfo({
            info: samplePaper,
            condition: yupPaper,
        });
        setShowForm(true);
    };
    const removePaper = async (data) => {
        console.log(data);
        const { isConfirmed } = await Swal.fire({
            title: 'Xoá quy trình?',
            text: data.name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
        if (isConfirmed) {
            const resultRemove = await paperApi.removePaper(data);
            if (resultRemove.result) {
                Swal.fire('Đã xoá.', 'success');
                getPaper();
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: resultRemove.noti,
                });
            }
        }
    };
    const columns = [
        { field: 'stt', headerName: 'STT', minWidth: 50, flex: 0.3 },
        {
            field: 'name',
            headerName: 'Quy trình',
            minWidth: 150,
            flex: 5,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.name}
                </div>
            ),
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            minWidth: 150,
            flex: 3,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: 'pre-line',
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {params.row.description}
                </div>
            ),
        },
        {
            field: 'link',
            headerName: 'Đường dẫn',
            minWidth: 150,
            flex: 2,
            renderCell: (params) => (
                <NavLink to={params.row.link} target="_blank">
                    {params.row.link}
                </NavLink>
            ),
        },
        {
            field: 'Actions',
            headerName: 'Tuỳ chỉnh',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <EditIcon
                        onClick={() => editPaperForm(params.row)}
                        style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon onClick={() => removePaper(params.row)} style={{ cursor: 'pointer' }} />
                </div>
            ),
        },
    ];
    return (
        <div>
            <div>
                {showForm && (
                    <TodoForm
                        show={showForm}
                        setShow={setShowForm}
                        confirmForm={confirmForm.confirm}
                        defaultValues={defaultForm}
                        formDataOrigin={formInfo.info}
                        title={confirmForm.title}
                        condition={formInfo.condition}
                    />
                )}
            </div>
            <div>
                <div>
                    <Button sx={{}} variant="contained" onClick={addPaperForm} component="label" startIcon={<AddBox />}>
                        Thêm tờ trình
                    </Button>
                </div>
            </div>
            <div>
                <TodoTable rows={dataPaper} columns={columns} />
            </div>
        </div>
    );
}

export default Paper;
