import style from './CheckCustomerAdmin.module.scss';
import TodoTable from '~/compoents/TodoTable/TodoTable.js';
import { convertMoney, convertDate } from '~/tool/convert';
import CheckPotential from '~/pages/compoents/CheckPotential/CheckPotential';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField } from '@mui/material';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ViewCustomrInfo from '~/pages/compoents/ViewCustomerInfo';
import customerApi from '~/api/customer/customer';
import { useState, useEffect } from 'react';

function CheckCustomerAdmin({ customerTable, setShow, show }) {
    const [dataCustomerID, setDataCustomerID] = useState(false);
    const [showCustomerInfoID, setShowCustomerInfoID] = useState(false);
    const viewCustomrID = async (data) => {
        const dataID = await customerApi.getCustomerIDInfo({ customerID: data.customerID });
        setDataCustomerID(dataID);
        setShowCustomerInfoID(true);
    };
    const columns = [
        {
            field: 'customerID',
            headerName: 'Xem',
            maxWidth: 30,
            renderCell: (params) => (
                <WysiwygIcon
                    onClick={() => viewCustomrID(params.row)}
                    style={{ cursor: 'pointer', marginRight: '8px' }}
                />
            ),
        },
        {
            field: 'stt',
            headerName: '.STT',
            maxWidth: 30,
        },
        {
            field: 'pic',
            headerName: '.Sale',
            minWidth: 100,
        },
        {
            field: 'name',
            headerName: '.Khách hàng',
            minWidth: 250,
        },
        {
            field: 'potential',
            headerName: 'Tiềm năng',
            minWidth: 60,
            renderCell: (params) => <CheckPotential potential={params.row.potential} />,
        },
        { field: 'status', headerName: 'Trạng thái', minWidth: 150 },
        {
            field: 'status_pay',
            headerName: 'Số tiền (₫)',
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#28529a' }}>
                        {convertMoney(params.row.pay)} ₫
                    </div>
                    <div style={{ fontSize: '10px' }}>{params.row.status_pay}</div>
                </div>
            ),
        },
        {
            field: 'date_pay',
            headerName: '.Ngày thanh toán',
            minWidth: 150,
            renderCell: (params) => <div style={{ color: 'rgb(40, 82, 154)' }}>{params.row.date_pay}</div>,
        },
        // {
        //     field: 'input',
        //     headerName: '.Ngày cập nhật',
        //     minWidth: 150,
        // },
        {
            field: 'date_ex',
            headerName: 'Ngày khám',
            minWidth: 150,
        },
        {
            field: 'type',
            headerName: '.Loại',
            minWidth: 150,
        },
        { field: 'package', headerName: 'Gói', minWidth: 150 },
        // { field: 'expected', headerName: 'Dự kiến (Đã bỏ)', minWidth: 150 },

        { field: 'contact', headerName: 'Liên hệ', minWidth: 150 },
        { field: 'source', headerName: 'Nguồn', minWidth: 150 },
        { field: 'note', headerName: 'Ghi chú', minWidth: 150 },
    ];

    return (
        <div className={style.checkCustomer}>
            {dataCustomerID !== false && (
                <ViewCustomrInfo show={showCustomerInfoID} setShow={setShowCustomerInfoID} data={dataCustomerID} />
            )}
            <div className={style.close}>
                <Button
                    size="small"
                    sx={{ padding: '7px 10px' }}
                    type="submit"
                    variant="contained"
                    component="label"
                    startIcon={<CloseIcon />}
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    Đóng
                </Button>
            </div>

            {show && (
                <div>
                    <TodoTable rows={customerTable} columns={columns} />
                </div>
            )}
        </div>
    );
}

export default CheckCustomerAdmin;
