// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckCustomerAdmin_checkCustomer__y3x4I {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000 !important;
  padding: 20px;
}

.CheckCustomerAdmin_close__0F2Zg {
  display: flex;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/compoents/CheckCustomerAdmin/CheckCustomerAdmin.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,wBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,oBAAA;AACJ","sourcesContent":[".checkCustomer {\n    background-color: #ffff;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 1000 !important;\n    padding: 20px;\n}\n\n.close {\n    display: flex;\n    justify-content: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkCustomer": `CheckCustomerAdmin_checkCustomer__y3x4I`,
	"close": `CheckCustomerAdmin_close__0F2Zg`
};
export default ___CSS_LOADER_EXPORT___;
