// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  /* border-radius: 10px; */
}

.ViewCustomerInfo_table__ODgRd {
  max-width: 100%;
  min-width: 40%;
  max-height: 80vh;
  overflow: auto;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  margin-top: 15px;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}

.ViewCustomerInfo_table__ODgRd table tr:first-child {
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: #f1f1f1 !important;
  cursor: pointer;
}

.ViewCustomerInfo_table__ODgRd td,
th {
  border: none;
  text-align: left;
  padding: 12px 10px;
  font-size: 13px;
}

.ViewCustomerInfo_table__ODgRd td {
  color: rgba(0, 0, 0, 0.87);
  min-width: 200px;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
} */
.ViewCustomerInfo_table__ODgRd tr {
  background-color: #ffffff;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.ViewCustomerInfo_actions__YXuUc {
  display: flex;
  align-items: center;
}

.ViewCustomerInfo_actions__YXuUc > div {
  margin-right: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/compoents/ViewCustomerInfo/ViewCustomerInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,yBAAA;AACJ;;AAEA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,oCAAA;EACA,mBAAA;EACA,gBAAA;EACA,+HAAA;AACJ;;AAEA;EAEI,WAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;EACA,oCAAA;EACA,eAAA;AACJ;;AAEA;;EAEI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,0BAAA;EACA,gBAAA;AACJ;;AAEA;;GAAA;AAIA;EACI,yBAAA;EACA,2CAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,eAAA;AAAJ","sourcesContent":["table {\n    border-collapse: collapse;\n    width: 100%;\n    /* border-radius: 10px; */\n}\n\n.table {\n    max-width: 100%;\n    min-width: 40%;\n    max-height: 80vh;\n    overflow: auto;\n    border: 1px solid rgba(224, 224, 224, 1);\n    border-radius: 10px;\n    margin-top: 15px;\n    /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */\n}\n\n.table table tr:first-child {\n    position: -webkit-sticky;\n    /* Safari */\n    position: sticky;\n    top: 0;\n    z-index: 0;\n    background-color: #f1f1f1 !important;\n    cursor: pointer;\n}\n\n.table td,\nth {\n    border: none;\n    text-align: left;\n    padding: 12px 10px;\n    font-size: 13px;\n}\n\n.table td {\n    color: rgba(0, 0, 0, 0.87);\n    min-width: 200px;\n}\n\n/* tr:nth-child(even) {\n    background-color: #dddddd;\n} */\n\n.table tr {\n    background-color: #ffffff;\n    border-bottom: 1px solid rgba(224, 224, 224, 1);\n}\n\n.actions {\n    display: flex;\n    align-items: center;\n}\n\n.actions > div {\n    margin-right: 10px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ViewCustomerInfo_table__ODgRd`,
	"actions": `ViewCustomerInfo_actions__YXuUc`
};
export default ___CSS_LOADER_EXPORT___;
