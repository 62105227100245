import * as yup from 'yup';

export const infoGroupMonth = [
    { name: 'quantity', label: 'Số lượng khám thực tế', type: 'number' },
    { name: 'dateBegin', label: 'Từ ngày', type: 'number' },
    { name: 'dateEnd', label: 'Đến ngày', type: 'number' },
];
export const yupInfoGroup = yup.object().shape({
    dateBegin: yup.string().required('Vui lòng nhập dữ liệu!'),
    dateEnd: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const monthsAR = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const charTypeSelect = ['hieudb', 'Khách đoàn', 'Khách lẻ'];
