import Login from '~/Auth/Login';
import Home from '~/pages/Home';
import Customer from '~/pages/Customer';
import Dashboard from '~/pages/Dashboard';
import ChangePassword from '~/Auth/ChangePassword';
import Statistical from '~/pages/Statistical';
import Procedure from '~/pages/Procedure';
import ProcedureInfo from '~/pages/ProcedureInfo';
import Total from '~/pages/Total';
import Week from '~/pages/Week';
import Paper from '~/pages/Paper';
import PaperInfo from '~/pages/PaperInfo';
import WeekAdmin from '~/pages/WeekAdmin';
import Warning from '~/pages/Warning';
import Group from '~/pages/Group';
import GroupAdmin from '~/pages/GroupAdmin';
import CustomerAdmin from '~/pages/CustomerAdmin';
import Month from '~/pages/Month';
import MonthAdmin from '~/pages/MonthAdmin';

import NullLayout from '~/layouts/NullLayout';
import DefaultLayout from '~/layouts/DefaultLayout';

const publickRoutes = [
    { path: '/login', component: Login, layout: NullLayout },
    { path: '/changepassword', component: ChangePassword, layout: NullLayout },
    { path: '/home', component: Home, layout: NullLayout },
];
var privateRoutes = [
    { path: '/customer', component: Customer, layout: DefaultLayout },
    { path: '/customeradmin', component: CustomerAdmin, layout: DefaultLayout },
    { path: '/week', component: Week, layout: DefaultLayout },
    { path: '/month', component: Month, layout: DefaultLayout },
    { path: '/monthadmin', component: MonthAdmin, layout: DefaultLayout },
    { path: '/weekadmin', component: WeekAdmin, layout: DefaultLayout },
    { path: '/warning', component: Warning, layout: DefaultLayout },
    { path: '/group', component: Group, layout: DefaultLayout },
    { path: '/groupadmin', component: GroupAdmin, layout: DefaultLayout },
    { path: '/dashboard', component: Dashboard, layout: DefaultLayout },
    { path: '/statistical', component: Statistical, layout: DefaultLayout },
    { path: '/total', component: Total, layout: DefaultLayout },
    { path: '/procedure', component: Procedure, layout: DefaultLayout },
    { path: '/procedureinfo', component: ProcedureInfo, layout: DefaultLayout },
    { path: '/paper', component: Paper, layout: DefaultLayout },
    { path: '/paperinfo', component: PaperInfo, layout: DefaultLayout },
    { path: '/', component: Home, layout: DefaultLayout },
];

export { publickRoutes, privateRoutes };
