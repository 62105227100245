import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';

export default function SearchData2({ data, addData }) {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);

        newValue != null ? addData(newValue) : addData('');
    };

    return (
        <Autocomplete
            options={data}
            getOptionLabel={(option) => option.label}
            onChange={handleOptionChange}
            value={selectedOption}
            sx={{ width: '100%', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} label="Tìm kiếm tên khách hàng..." variant="outlined" />}
        />
    );
}
