import * as yup from 'yup';

const statusOld = [
    {
        value: 'Cập nhật thông tin',
        label: 'Cập nhật thông tin',
    },
    {
        value: 'Ốp kí hợp đồng',
        label: 'Ốp kí hợp đồng',
    },
    {
        value: 'Tổ chức khám',
        label: 'Tổ chức khám',
    },
    {
        value: 'Trả hồ sơ thanh lý',
        label: 'Trả hồ sơ thanh lý',
    },
];

export const infoCustomerOld = [
    { name: 'name', label: 'Khách hàng:', type: 'text' },
    {
        name: 'status',
        label: 'Trạng thái',
        type: 'select',
        select: statusOld,
        defaultSelect: statusOld[0].value,
    },
    { name: 'note', label: 'Ghi chú', type: 'note' },
];

export const yupCustomerOld = yup.object().shape({
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
});

export const infoCustomerNew = [
    { name: 'name', label: 'Khách hàng:', type: 'text' },
    { name: 'amount', label: 'Doanh thu dự kiến:', type: 'text' },
    { name: 'date', label: 'Thời điểm triển khai:', type: 'text' },
    { name: 'package', label: 'Gói khám:', type: 'text' },
    { name: 'note', label: 'Ghi chú', type: 'note' },
];

export const yupCustomerNew = yup.object().shape({
    name: yup.string().required('Vui lòng nhập dữ liệu!'),
});
